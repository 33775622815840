<template>
    <div class="container">
        <div class="auth-form">
            <div class="row">
                <div class="col text-center login-logo-row">
                    <img src="@/assets/qfecard-logo-white.svg" alt="logo">
                </div>
            </div>

            <div class="form-fields">
                <div id="link-invalid" class="text-center">
                    <h3 class="error">Your profile is suspended!</h3>
                    <font-awesome-icon size="2x" icon="heart-broken"></font-awesome-icon>
                    <div class="space"></div>
                    <div class="welcome-separator"></div>
                    <p class="text-center">It appears that your qfecard account is no longer active.</p>
                    <p class="text-center" v-if="trialXPNiceDate">Your account trial period has expired on: <strong>{{trialXPNiceDate}}</strong></p>
                    <p class="text-center">If you think this has happened in error, please contact the administrator that had on-boarded you.</p>
                    <div class="row justify-content-center mb-3">
                        <div class="col-4">
                        <button-field color="secondary"
                                      type="submit"
                                      block
                                      @click="logout"
                                      icon="sign-out-alt"
                                      >
                            {{$ml.get('LOG_OUT')}}
                        </button-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Parse from "parse";
    export default {
        name: "ProfileSuspended",
        data() {
            return {
                loading: false,
            }
        },
        computed: {
            trialXPNiceDate(){
                let curUser = Parse.User.current();
                if(curUser && curUser.attributes.trialUntil){
                    return moment(curUser.trialUntil).format('D/MM/Y');
                }else{
                    return false;
                }
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout').then(() => {
                    this.$store.commit('setIsAuthenticated', false);
                    this.$store.commit('setUser', null);
                    this.$router.push('/auth');
                });
            },
        }
    }
</script>

<style scoped>
    .container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .welcome-separator {
        width: 100%;
        border-top: 1px solid var(--color-secondary);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .space {
        width: 100%;
        background: transparent;
        opacity: 0;
        width: 100%;
        height: 10px;
    }

    .welcome-message {
        font-size: 0.8em;
    }

    .form-loader, .form-fields {
        min-height: 290px;
    }

    .invitation-loading {
        width: 100%;
        text-align: center;
        padding-top: 100px;
    }

    .company-logo{
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #000000;
    }
</style>
