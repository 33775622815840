<template>
    <form class="auth-form"
          @submit.prevent="submitForm">
        <div class="row">
            <div class="col text-center login-logo-row">
                <img src="@/assets/qfecard-logo-white.svg" alt="logo">
            </div>
        </div>
        <div v-if="!success">
            <div class="row justify-content-center mb-3">
                <div class="col-9">
                    <input-field name="email"
                                 id="email"
                                 type="email"
                                 :error="error"
                                 :value="form.email"
                                 v-model="form.email">{{$ml.get('EMAIL')}}
                    </input-field>
                </div>
            </div>
            <div class="row justify-content-center mb-3">
                <div class="col-4">
                    <button-field color="secondary"
                                  type="submit"
                                  icon="paper-plane"
                                  block
                                  :disabled="loading"
                                  :loading="loading">
                        {{$ml.get('SEND_EMAIL')}}
                    </button-field>
                </div>
            </div>
        </div>
        <div v-if="success">
            <div class="row align-items-center">
                <div class="col text-center">
                    <h3 class="color-secondary">{{$ml.get('Success')}}</h3>
                    <p v-html="$ml.get('passwordForgottenSuccess')"></p>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col text-center small">
                <router-link :to="{name: 'auth'}">{{$ml.get('LOGIN')}}</router-link>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "AuthenticateForgotten",
        data() {
            return {
                form: {email: ''},
                loading: false,
                success: false,
                error: ''
            }
        },
        methods: {
            submitForm() {
                if (!this.loading) {
                    this.loading = true;
                    this.error = '';
                    this.$store.dispatch('passwordReset', this.form).then(response => {
                        if (parseInt(response.status) === 200) {
                            this.success = true;
                        } else {
                            this.error = this.$ml.get(`passwordForgottenError${response.status}`)
                        }
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>
    h3 {
        margin-top: 11px;
        margin-bottom: 16px;
    }
</style>
