<template>
    <div>
        <h3 class="text-center">
            {{$ml.get('SUSPEND_USER')}}
        </h3>
        <div class="font-bebas-neue">
            {{$ml.get('USER_TO_BE_SUSPENDED')}}
        </div>
        <div class="user-details">
                <img v-if="selectedUser.attributes.avatar"
                     class="comp-logo"
                     :src="selectedUser.attributes.avatar._url" />
                <span>{{selectedUser.attributes.firstName}} {{selectedUser.attributes.lastName}}</span>
                <span class="text-small">{{selectedUser.attributes.login}}</span>
        </div>

        <div v-if="isSuspended" >
            <div class="pt-4">
                <checkbox-field color="secondary"
                                name="confirm"
                                id="confirm"
                                :value="confirm"
                                v-model="confirm">
                    {{$ml.get('restore_user_confirm_checkbox')}}
                </checkbox-field>
            </div>
            <div class="row pt-4 justify-content-center">
                <div class="col-6">
                    <button-field color="dark"
                                  icon="sun"
                                  :loading="loading"
                                  :disabled="loading || !confirm"
                                  block
                                  @click="toggleSuspend">{{$ml.get('RESTORE_USER')}}
                    </button-field>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="pt-4">
                <checkbox-field color="secondary"
                                name="confirm"
                                id="confirm"
                                :value="confirm"
                                v-model="confirm">
                    {{$ml.get('suspend_user_confirm_checkbox')}}
                </checkbox-field>
                <p>{{$ml.get('beware_suspend')}}</p>
            </div>

            <div class="row pt-4 justify-content-center">
                <div class="col-6">
                    <button-field color="dark"
                                  icon="moon"
                                  :loading="loading"
                                  :disabled="loading || !confirm"
                                  block
                                  @click="toggleSuspend">{{$ml.get('SUSPEND_USER')}}
                    </button-field>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
    export default {
        name: "DashboardUserSuspend",
        computed: {
            selectedUsers() {
                return this.$store.getters.selectedModalData('visibleUsers');
            },
            isSuspended() {
                if(this.selectedUser && this.selectedUser.attributes) {
                    return this.selectedUser.attributes.suspended;
                }else {
                    return false;
                }
            },
            selectedUser() {
                return this.$store.getters.selectedModalData('visibleUsers')[0];
            },
        },
        data() {
            return {
                confirm: false,
                loading: false,
            }
        },
        methods: {
            toggleSuspend() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('saveUser', {id: this.selectedUser.id, suspended: !this.isSuspended}).then(() => {
                        self.loading = false;
                        let msg = !this.isSuspended ? this.$ml.get('notification_success_user_suspend') : this.$ml.get('notification_success_user_restored');
                        self.$notify({
                            group: 'notification',
                            type: 'success',
                            text: msg,
                        });
                        self.$store.dispatch('getAvailableUsers');
                        self.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.loading = false;
                        self.$checkTokenExp(e);
                    });
                }
            },
            restoreClients() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('restoreClient').then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_restored'),
                        });
                        this.$store.commit('setCardCompany', this.$store.state.userCompany);
                        self.$store.dispatch('getAgencyClients');
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    });
                }
            }
        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding-top: 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--bg-grey);
    }
    li span {
        font-size: 1.5em;
    }
    img.comp-logo {
        max-height: 50px;
        margin-right: 10px;
        margin-bottom: -14px;
    }
</style>
