<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{(id===null)?$ml.get('ADD_NEW_USER'):$ml.get('EDIT_USER_DETAILS')}}
        </h3>

        <div class="row">
            <div class="col-4">
                <dropzone-field
                    :name="'avatar'"
                    :id="'fileInput'"
                    :accept="`image/*`"
                    v-model="form.avatar">
                    <img :src="form.avatar._url"
                         v-if="form.avatar !== undefined">
                    <template v-else>
                        <div>
                            {{$ml.get('ADD_PROFILE_PIC')}}<br>(square .jpg or .png)
                        </div>
                    </template>
                </dropzone-field>
            </div>

            <div class="col-8">
                <div class="row">
                    <div class="col-12">
                        <input-field :id="`first-name`"
                                     :name="'first-name'"
                                     :value="form.firstName"
                                     :required="true"
                                     @validation-result="validationCollector"
                                     v-model="form.firstName">{{$ml.get('FIRST_NAME')}}
                        </input-field>
                    </div>
                    <div class="col-12">
                        <input-field :id="`last-name`"
                                     :name="'last-name'"
                                     :value="form.lastName"
                                     :required="true"
                                     @validation-result="validationCollector"
                                     v-model="form.lastName">{{$ml.get('LAST_NAME')}}
                        </input-field>
                    </div>

                    <div class="col-12">
                        <input-field :id="`email`"
                                     :name="'email'"
                                     :value="form.username"
                                     :required="true"
                                     @validation-result="validationCollector"
                                     v-model="form.username">{{$ml.get('LOGIN_EMAIL')}}
                        </input-field>
                    </div>

                    <div v-if="!id" class="col-12">
                        <input-field :id="`pass1`"
                                     :name="'pass1'"
                                     :type="`text`"
                                     :value="form.password"
                                     v-model="form.password">{{$ml.get('PASSWORD_OPTIONAL')}}
                        </input-field>
                    </div>
                </div>

            </div>



        </div>
        <div class="row">
            <div id="font-selector" class="col-12">
                <label class="form-label select-2-label">{{$ml.get('LINK_TO_COMPANY')}}</label>
                <Select2
                        v-model="form.company.id"
                        :options="companies"
                        :placeholder="$ml.get('COMPANY')"
                        :settings="{
                        templateResult: companyPreview,
                        templateSelection: selectionPreview,
                        escapeMarkup: function(stuff){return stuff}
                    }"
                />
            </div>
            <div class="col-12">
                <p class="simple-clarification">Leave empty to allow the invited user to setup their own company details.</p>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-6 mb-2">
                <checkbox-field color="secondary"
                                :value="form.hasTrial"
                                v-model="form.hasTrial"
                >{{$ml.get('this_is_trial_account')}}
                </checkbox-field>
            </div>
            <div class="col-6 mb-2" v-if="form.company.id === 0 || form.company.id === '0'">
                <checkbox-field color="secondary"
                                :value="form.canSetupAgency"
                                v-model="form.canSetupAgency"
                >{{$ml.get('user_can_setup_agency')}}
                </checkbox-field>
            </div>

            <div v-if="form.hasTrial" class="col-12">
                <label class="form-label form-date-label">{{$ml.get('select_trial_end_date')}}</label>
                <input type="date" class="form-field-date" v-model="form.trialUntil" :min="minDate"/>
            </div>
        </div>

        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="(id===null)?'plus':'save'"
                              type="submit"
                              :disabled="isFormDisabled || loading"
                              :loading="loading"
                              block>{{(id===null)?$ml.get('ADD'):$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    import Select2 from "v-select2-component";
    import moment from "moment";
    export default {
        name: "DashboardUserForm",
        components: {
            Select2,
        },
        computed: {
            id() {
                return this.$store.state.dashboardModalComponent.data.id
            },
            minDate() {
                return moment().format('Y-MM-DD');
            },
            selectedUser() {
                return this.$store.getters.selectedModalData('visibleUsers');
            },
            companies() {
                let companies = [{
                        id: 0,
                        text: "None",
                        fullData: {
                            name: "None",
                            logo: {
                                _url: require('../../assets/noun-empty.svg')
                            }
                        }
                }];
                if(this.$store.state.agencyClients){
                    this.$store.state.agencyClients.forEach((comp) => {
                        let element = {
                            id: comp.id,
                            text: comp.attributes.name,
                            fullData: comp.attributes
                        };
                        companies.push(element);
                    })
                }
                return companies;
            },
            isFormDisabled(){
                return this.errors.length > 0;
            }
        },
        data() {
            let initialAttr = this.$store.getters.selectedModalData('visibleUsers');
            let dataObj = {};
            dataObj = {
                theId: initialAttr ? initialAttr.id : null,
                form: {
                    id : initialAttr ? initialAttr.id : null,
                    username: initialAttr ? initialAttr.attributes.username : '',
                    firstName: initialAttr ? initialAttr.attributes.firstName : '',
                    lastName:  initialAttr ? initialAttr.attributes.lastName : '',
                    mobilePhone: initialAttr ? initialAttr.attributes.mobilePhone : '',
                    canSetupAgency: initialAttr ? initialAttr.attributes.canSetupAgency : false,
                    company: {id: 0},
                    randString: '',
                    hasTrial: false,
                    trialUntil: initialAttr ? initialAttr.attributes.trialUntil : null,
                    select: {
                        selectError: '',
                        options: [
                            {
                                value: 'initState',
                                text: '',
                                disabled: true
                            }
                        ],
                        value: 'initState'
                    }
                },
                loading: false,
                errors: []
            }

            if(!initialAttr){
                dataObj.form.password = this.generateRandomString(20);
            }

            if(initialAttr && initialAttr.company){
                dataObj.company.id = initialAttr.attributes.company.id;
            }

            return dataObj;
        },
        mounted() {
            if (this.selectedUser !== null) {
                this.form = {...this.form, ...this.selectedUser.attributes}
                if(this.form.trialUntil) {
                    this.form.hasTrial = true;
                    this.form.trialUntil = moment(this.form.trialUntil).format('Y-MM-DD');
                }
            }
        },
        methods: {

            companyPreview(choice){
                let markup = '';
                if(choice.fullData){
                    let item = choice.fullData;

                    if(item.suspended){
                        markup = `<div class="company suspended">`;
                    }else {
                        markup = `<div class="company">`;
                    }
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="`+item.logo._url+`" />`;
                    markup += `</span>`;
                    markup += `<h3>`+item.name+`</h3>`;
                    markup +=`</div>`;
                }
                return markup;
            },
            selectionPreview(selection){

                let markup = '';
                if(selection.fullData){
                    let item = selection.fullData;
                    markup = `<div class="company selection">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="`+item.logo._url+`" />`;
                    markup += `</span>`;
                    markup += `<h3>`+item.name+`</h3>`;
                    markup +=`</div>`;
                }
                return markup;
            },
            validationCollector(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.errors.remove(inputValidation.name);
                }else {
                    if(this.errors.indexOf(inputValidation.name) === -1){
                        this.errors.push(inputValidation.name);
                    }
                }
            },

            generateRandomString(length) {
                let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                let result = '';
                for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
                return result;
            },

            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;

                    this.form.randString = this.generateRandomString(20);

                    this.$store.dispatch('saveUser', {id: this.id, ...this.form}).then(() => {
                        this.$store.dispatch('getAvailableUsers').then(() => {
                            this.loading = false;
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_success_saved'),
                            });
                            this.$store.commit('toggleDashboardModal');
                        });
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                        this.loading = false;
                    });

                }
            }
        },
        watch: {
            // form: {
            //     handler(newForm){
            //         if(newForm.trialUntil){
            //             console.log("New trial date: ", newForm.trialUntil);
            //         }
            //     },
            //     deep: true,
            //     immediate: true
            // },
            id: {
                handler(newUserId) {
                    let self = this;
                    if(newUserId){
                        self.$store.dispatch('getUserEmail', {
                            userId: newUserId
                        }).then(response => {
                            if(response.email){
                                self.form.email = response.email;
                            }
                        });
                    }
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>


<style scoped>

.dropzone-container {
    height: 180px !important;
}

.select2-container{
    width: 100% !important;
}

.simple-clarification {
    color: #999999;
    font-style: italic;
    font-size: 0.75em;
}
.form-label {
    position: relative;
    width: 100%;
    color: var(--color-dark);
    /* transition: all 0.25s ease-in-out; */
    text-transform: uppercase;
    font-size: 10px;
    cursor: text;
    pointer-events: none;
}

.color-error > .form-label,
.form-has-value > .form-label {
    top: 0;
    font-size: 10px;
}

.form-field:focus ~ .form-label {
    top: 0;
    font-size: 10px;
}
</style>
