import Parse from 'parse'
import Api from './Api'
import {_} from 'vue-underscore';

function generateRandomString(length) {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}

export const ClientService = {

    getAgencyClients(context, filters = null) {
        let self = this;
        let companies = Parse.Object.extend('Company');
        let query = new Parse.Query(companies);

        let employees = Parse.Object.extend('Employee');
        let relatedEmployees = new Parse.Query(employees);
        let curUser = Parse.User.current();
        if(!curUser.attributes.isAdmin){
            query.equalTo('clientOf', context.state.userCompany);
        }
        query.ascending('name');

        relatedEmployees.matchesQuery('company', query);

        //query.include('officeAddress');
        if(filters && filters.searchTerm){
            let regEx = '.*'+filters.searchTerm+".*";

            let compNameQuery =new Parse.Query(companies);
            compNameQuery.matches('name', regEx, 'i');

            let vatQuery = new Parse.Query(companies);
            vatQuery.matches('vatNumber', regEx, 'i');

            let websiteQuery = new Parse.Query(companies);
            websiteQuery.matches('website', regEx, 'i');

            let combinedOrQueries = Parse.Query.or(compNameQuery, vatQuery, websiteQuery);

            let finalQuery = Parse.Query.and(query, combinedOrQueries)

            return new Promise((resolve, reject) => {
                finalQuery.find().then(response => {
                    context.commit('setAgencyClients', response);
                    resolve(response)
                }).catch(error => {
                    self.$checkTokenExp(error)
                    reject(error);
                })
            });

            //query.contains('lastName', filters.searchTerm);
            //query.contains('login', filters.searchTerm);
        } else {
            return new Promise((resolve, reject) => {
                relatedEmployees.find().then(function(employeeResults){
                    let groupedResults = _.groupBy(employeeResults, function(employee) {
                        return employee.get('company').id;
                    });
                    query.find().then(response => {
                        response.forEach(function(element){
                            if(groupedResults[element.id]){
                                element.employeeCount = groupedResults[element.id].length;
                            }else{
                                element.employeeCount = 0;
                            }

                        });
                        context.commit('setAgencyClients', response);
                        resolve(response)
                    }).catch(error => {
                        self.$checkTokenExp(error);
                        reject(error);
                    })

                });


            });
        }
    },

    changeImpersonation(context, data) {
        let pointer = {"__type": "Pointer", "className": "Company", "objectId": data.id}

        let saver = data;
        if(context.state.userCompany){
            saver = context.state.userCompany;
        }
        saver.set('impersonating', pointer);
        return new Promise((resolve, reject) => {
            saver.save().then(response => {
                context.dispatch('getCardConfig');
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },

    selectCard(context, selectedAddressId){
        let selected = context.state.officeAddresses.find(obj => {
            if(obj.id === selectedAddressId){
                return obj;
            }else{
                return false;
            }
        });
        return selected;
    },

    saveClient(context, data) {

        //let parentAgency = { "__type": "Pointer", "className": "Company", "objectId": context.state.userCompany }

        if (data.id === null) {
            let newClient = Parse.Object.extend('Company');
            let clientRecord = new newClient();

            return new Promise((resolve, reject) => {
                clientRecord.set('name', data.name);
                clientRecord.set('vatNumber', data.vatNumber);
                clientRecord.set('billingAddress', data.billingAddress);
                clientRecord.set('responsible', data.responsible);
                clientRecord.set('telephone', data.telephone);
                clientRecord.set('billingEmail', data.billingEmail);
                clientRecord.set('website', data.website);
                clientRecord.set('frameSecret', generateRandomString(20));

                if(typeof (data.isAgency) !== 'undefined') {
                    clientRecord.set('isAgency', data.isAgency);
                } else {
                    clientRecord.set('isAgency', false);
                }

                if(data.hasTrial === false){
                    clientRecord.set('trialUntil', null);
                }else if(data.trialUntil){
                    clientRecord.set('trialUntil', new Date(data.trialUntil));
                }

                if(context.state.userCompany){
                    clientRecord.set('clientOf', context.state.userCompany);
                }

                if (data.logo && data.logo.name) {
                    let file = new Parse.File(data.logo.name, data.logo);
                    clientRecord.set('logo', file);
                } else {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", '/img/icon.png', true);
                    xhr.responseType = "blob";
                    xhr.onload = function () {
                        //console.log(this.response);
                        let reader = new FileReader();
                        reader.onload = function() {
                            //let res = event.target.result;
                            //console.log("Res" , res, e);
                        }
                        let file = this.response;
                        let parseFile = new Parse.File('card-icon.png', file);
                        clientRecord.set('logo', parseFile);
                        reader.readAsDataURL(file);
                        //debugger;
                    };
                    xhr.send()

                }

                clientRecord.save().then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });

            });
        } else {
            return new Promise((resolve, reject) => {
                context.getters.selectedModalData('agencyClients').set('name', data.name);
                context.getters.selectedModalData('agencyClients').set('vatNumber', data.vatNumber);
                context.getters.selectedModalData('agencyClients').set('billingAddress', data.billingAddress);
                context.getters.selectedModalData('agencyClients').set('responsible', data.responsible);
                context.getters.selectedModalData('agencyClients').set('telephone', data.telephone);
                context.getters.selectedModalData('agencyClients').set('billingEmail', data.billingEmail);
                //context.getters.selectedModalData('employeeCards').set('address', data.address);
                context.getters.selectedModalData('agencyClients').set('telephone', data.telephone);
                context.getters.selectedModalData('agencyClients').set('clientOf', context.state.userCompany);

                if(typeof (data.isAgency) !== 'undefined') {
                    context.getters.selectedModalData('agencyClients').set('isAgency', data.isAgency);
                }

                if (data.logo !== undefined && data.logo.file) {
                    let file = new Parse.File(data.logo.name, data.logo.file);
                    context.getters.selectedModalData('agencyClients').set('logo', file);
                }
                if(!context.getters.selectedModalData('agencyClients').attributes.frameSecret){
                    context.getters.selectedModalData('agencyClients').set('frameSecret', generateRandomString(20));
                }

                if(data.hasTrial === false){
                    context.getters.selectedModalData('agencyClients').set('trialUntil', null);
                }else if(data.trialUntil){
                    context.getters.selectedModalData('agencyClients').set('trialUntil', new Date(data.trialUntil));
                }

                context.getters.selectedModalData('agencyClients').save().then(response => {
                    context.dispatch('getAgencyClients');
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        }
    },

    createCardConfig(context, data){
        let template = Parse.Object.extend('CardTemplate');
        let cardConfig = Parse.Object.extend('CardConfig');

        let newConfig = new cardConfig;
        let query = new Parse.Query(template);
        query.equalTo('objectId', data.cardTemplate);

        query.first().then(response => {

            let defaults = response.attributes.availableParams;

            defaults.forEach(function(setting){
                if(setting.fieldType !== "DropzoneField"){
                    let infoToSave = data[setting.field];

                    if(!infoToSave) {
                        infoToSave = setting.defaultValue;
                    }
                    if(setting.fieldType === "SliderField"){
                        infoToSave = parseInt(data[setting.field]);
                    }
                    newConfig.set(setting.field, infoToSave);

                }
            });

            // let fileLogo = new Parse.File(data.imgLogo.name, data.imgLogo);
            // clientRecord.set('imgLogo', fileLogo);
            for (const k in data.imgFiles){
                if(data.imgFiles[k] !== null){

                    let file = new Parse.File(data.imgFiles[k].name, data.imgFiles[k]);
                    newConfig.set(k, file);
                }
            }

            if(data.appIcon){
                let appIconFile = new Parse.File(data.appIcon.name, data.appIcon);
                newConfig.set('appIcon', appIconFile);
            }

            let compPointer = {"__type": "Pointer", "className": "Company", "objectId": data.officeAddress.compId ? data.officeAddress.compId : context.state.cardCompany.id}
            let templatePointer = {"__type": "Pointer", "className": "CardTemplate", "objectId": data.cardTemplate}

            if(data.fontFamily){
                let fontFamilyPointer = {"__type": "Pointer", "className": "FontFamily", "objectId": data.fontFamily}
                newConfig.set('relatedFont', fontFamilyPointer);
            }

            if(data.baseFontSize) {
                newConfig.set('baseFontSize', parseInt(data.baseFontSize));
            }

            newConfig.set('company', compPointer);
            newConfig.set('template', templatePointer);
            newConfig.set('designVer', 1);

            return new Promise((resolve, reject) => {
                newConfig.save().then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            });
        });

    },
    suspendClient(context) {
        return new Promise((resolve, reject) => {

            let companiesToSuspend = context.getters.selectedModalData('agencyClients');
            let savePromises = [];

            companiesToSuspend.forEach(function(element){
                element.set('suspended', true);
                savePromises.push(element.save());
            });

            Promise.all(savePromises).then(response => {
                //console.log("Successfully suspended objects: ", response);
                resolve(response);
            }).catch(err => {
                //console.log("Unable to delete objects: ", err);
                reject(err);
            });

        });
    },
    restoreClient(context) {
        return new Promise((resolve, reject) => {

            let companiesToSuspend = context.getters.selectedModalData('agencyClients');
            let savePromises = [];

            companiesToSuspend.forEach(function(element){
                element.set('suspended', false);
                savePromises.push(element.save());
            });

            Promise.all(savePromises).then(response => {
                //console.log("Successfully suspended objects: ", response);
                resolve(response);
            }).catch(err => {
                //console.log("Unable to delete objects: ", err);
                reject(err);
            });

        });
    },
    deleteClient(context) {
        return new Promise((resolve, reject) => {

            let companiesToDelete = context.getters.selectedModalData('agencyClients');
            let compIds = [];

            companiesToDelete.forEach(function(element){
                compIds.push(element.id);
            });

            let cards = Parse.Object.extend('Employee');
            let cardConfig = Parse.Object.extend('CardConfig');
            let addresses = Parse.Object.extend('OfficeAddress');

            let queryCards = new Parse.Query(cards);
            let queryConfig = new Parse.Query(cardConfig);
            let queryAddress = new Parse.Query(addresses);

            queryCards.containedIn('company', compIds);
            queryConfig.containedIn('company', compIds);
            queryAddress.containedIn('company', compIds);

            let promiseCards = queryCards.find();
            let promiseConfig = queryConfig.find();
            let promiseAddress = queryAddress.find();

            let cardsToDelete = [],
                configToDelete = [],
                addressesToDelete = [];

            Promise.all([promiseCards, promiseConfig, promiseAddress]).then(function(values) {
                cardsToDelete = values[0];
                configToDelete = values[1];
                addressesToDelete = values[2];

                let delPromiseCards = Parse.Object.destroyAll(cardsToDelete);
                let delPromiseConfig = Parse.Object.destroyAll(configToDelete);
                let delPromiseAddress = Parse.Object.destroyAll(addressesToDelete);
                let delPromiseCompanies = Parse.Object.destroyAll(companiesToDelete);

                Promise.all([delPromiseCards,delPromiseConfig, delPromiseAddress, delPromiseCompanies]).then(response => {
                    //console.log("Successfully deleted objects: ", response);
                    resolve(response);
                }).catch(err => {
                    //console.log("Unable to delete objects: ", err);
                    reject(err);
                });

            });

        });
    },
    regenerateFrameLink(context){
        let newFrameSecret = generateRandomString(20);
        let client = context.getters.selectedModalData('agencyClients');
        client.set('frameSecret', newFrameSecret);
        return new Promise((resolve, reject) => {
            client.save().then(resp => {
                context.dispatch('getAgencyClients');
                resolve(resp);
            }).catch(err => {
                reject(err);
            })
        });
    },
    goToClientCards(context, data) {
        let formData = new FormData();
        formData.append('action', 'sendCardLink');
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify({
            sender_id: context.state.user.id,
            employee_ids: data
        }));
        
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
};
