<template>
    <div class="form-component"
         ref="sliderInputRef"
         :class="assignClasses">
        <input class="form-field"
               type="number"
               :name="name"
               :min="min"
               :max="max"
               :id="id"
               v-model="realValue">
        <input type="range"
               class="slider-field"
               :min="min"
               :max="max"
               :step="step"
               :value="value"
               @input="updateValue($event.target.value)">
        <label class="form-label"
               :for="id"><slot></slot></label>
        <span class="form-error font-bebas-neue">{{error}}</span>
    </div>
</template>

<script>
    export default {
        name: "SliderField",
        props: {
            name: String,
            id: String,
            type: {
                type: String,
                default: 'text'
            },
            error: {
                type: String,
                default: ''
            },
            value: [String, Number],
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            },
            step: {
                type: Number,
                default: 1
            },
            color: {
                type: String,
                default: ''
            },
        },
        computed: {
            assignClasses() {
                let resultClasses = '';
                if (this.value !== '') {
                    resultClasses += 'form-has-value ';
                }
                if (this.error.length) {
                    resultClasses += 'color-error';
                } else {
                    switch (this.color) {
                        case 'primary':
                            resultClasses += 'color-primary';
                            break;
                        case 'secondary':
                            resultClasses += 'color-secondary';
                            break;
                        case 'error':
                            resultClasses += 'color-error';
                            break;
                        default:
                            break;
                    }
                }
                return resultClasses;
            },
            realValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    if (!isNaN(newValue.substr(newValue.length - 1))) {
                        if (parseInt(newValue) < this.min) {
                            this.$emit('input', this.min);
                        } else if (parseInt(newValue) > this.max) {
                            this.$emit('input', this.max);
                        } else {
                            this.$emit('input', parseInt(newValue));
                        }
                    } else {
                        this.$emit('input', 80);
                    }
                }
            }
        },
        data() {
            return {
                sliderWidth: 100
            }
        },
        mounted() {
            this.resizeSlider();
            window.removeEventListener('resize', this.resizeSlider());
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeSlider());
        },
        methods: {
            updateValue(value) {
                if (!isNaN(value.substr(value.length - 1))) {
                    this.$emit('input', parseInt(value));
                } else {
                    value = 80;
                }
            },
            resizeSlider() {
                this.sliderWidth = this.$refs.sliderInputRef.clientWidth + 10;
            }
        }
    }
</script>

<style scoped>
    .form-component {
        min-height: 50px;
        position: relative;
        padding-top: 14px;

        /*border: 1px solid red;*/
    }

    .form-field {
        width: 100%;
        border: 0px solid var(--color-grey);
        border-bottom-width: 1px;
        background: transparent;
        font-size: 14px;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
    }

    .form-field:focus {
        box-shadow: none;
        outline: none;
        color: var(--color-dark);
        border-bottom-color: var(--color-dark);
    }

    .form-label {
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
        /*text-transform: uppercase;*/
        font-size: 12px;
        cursor: text;
        pointer-events: none;
    }

    .color-error > .form-label,
    .form-has-value > .form-label {
        top: 0;
        font-size: 10px;
    }

    .form-field:focus ~ .form-label {
        top: 0;
        font-size: 10px;
    }

    .form-has-error > .form-error {
        opacity: 1;
    }

    /* The slider itself */
    .slider-field {
        -webkit-appearance: none; /* Override default CSS styles */
        appearance: none;
        width: 100%; /* Full-width */
        height: 10px; /* Specified height */
        background: transparent; /* Grey background */
        outline: none; /* Remove outline */
        position: absolute;
        left: 0;
        top: 34px;
    }

    .slider-field:focus {
        outline: none;
    }

    /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
    .slider-field::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid var(--color-dark);
        width: 0; /* Set a specific slider handle width */
        height: 0; /* Slider handle height */
        background: transparent; /* Green background */
        cursor: pointer; /* Cursor on hover */
    }

    .slider-field::-moz-range-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid var(--color-dark);
        width: 0; /* Set a specific slider handle width */
        height: 0; /* Slider handle height */
        background: transparent; /* Green background */
        cursor: pointer; /* Cursor on hover */
    }

    .color-error > .form-field:focus ~ .form-label,
    .color-error > .form-label,
    .color-error > .form-field,
    .color-error > .form-error {
        color: var(--color-error);
    }

    .color-error > .form-field {
        border-bottom-color: var(--color-error);
    }

    .color-error > .form-error {
        opacity: 1;
    }

    .color-error > .form-field:focus ~ .form-error,
    .color-error > .form-field:focus ~ .form-label {
        color: var(--color-error-dark);
    }

    .color-error > .form-field:focus {
        color: var(--color-error-dark);
        border-bottom-color: var(--color-error-dark);
    }

    .color-primary > .form-field:focus ~ .form-label,
    .color-primary > .form-label,
    .color-primary > .form-field,
    .color-primary > .form-error {
        color: var(--color-primary);
    }

    .color-primary > .form-field {
        border-bottom-color: var(--color-primary);
    }

    .color-primary > .form-field:focus ~ .form-label {
        color: var(--color-primary-dark);
    }

    .color-primary > .form-field:focus {
        color: var(--color-primary-dark);
        border-bottom-color: var(--color-primary-dark);
    }

    .color-secondary > .form-field:focus ~ .form-label,
    .color-secondary > .form-label,
    .color-secondary > .form-field,
    .color-secondary > .form-error {
        color: var(--color-secondary);
    }

    .color-secondary > .form-field {
        border-bottom-color: var(--color-secondary);
    }

    .color-secondary > .form-field:focus ~ .form-label {
        color: var(--color-secondary-dark);
    }

    .color-secondary > .form-field:focus {
        color: var(--color-secondary-dark);
        border-bottom-color: var(--color-secondary-dark);
    }
</style>
