<template>
    <div class="qf-wizzard-container">
        <div class="qf-wizzard h100">
            <div class="qf-wizzard-flex">
                <div class="wizzard-tab-phone">
                    <div id="qf-wizzard-frame">
                        <div class="wrapper-phone iphone sc06">
                            <iframe class="cardPreview"
                                    name="cardPreview"
                                    :src="`${apiUrl}cards/render/?pr=1&t=${selectedTemplateSlug}&d=1`"  frameborder="0"></iframe>
                        </div>
                    </div>
                    <!-- APP ICONS -->

                </div>
                <div class="wizzard-tab-content">

                    <!-- DESIGN PARAMETERS -->
                    <div v-if="isStepActive(1)"  class="step-container h100"
                         v-bind:key="`tabStep2`"
                         :class="isStepActive(1)">

                        <form v-if="form" :action="`${apiUrl}cards/preview/?pr=1&d=0&t=${selectedTemplateSlug}`"
                              method="post"
                              target="cardPreview"
                              ref="cardPreview">
                            <input type="hidden" name="preview_data" value="1"/>

                            <div class="row h100">
                                <div class="col-8">
                                    <!-- TEMPLATE -->
                                    <div class="step-container step-templates"
                                         v-bind:key="`tabStep1`"
                                         :class="isTabActive(1)">
                                        <div v-if="availableCards.length > 0" class="cards-grid">
                                            <div v-for="(cardTemplate,index) in availableCards"
                                                 class="wrapper-phone iphone template"
                                                 @click="selectCard(cardTemplate.attributes.nice_name, cardTemplate)"
                                                 v-bind:key="index">
                                                <transition enter-active-class="animated fadeIn"
                                                            leave-active-class="animated fadeOut"
                                                            mode="out-in">
                                                    <font-awesome-icon
                                                        v-if="cardTemplate.id==selectedNewTemplate.id"
                                                        icon="check-circle"/>
                                                </transition>
                                                <img v-if="cardTemplate.attributes.screenshot"
                                                     :src="cardTemplate.attributes.screenshot._url" />
                                                <h3 class="font-bebas-neue"><span>{{cardTemplate.attributes.name}}</span></h3>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- APP ICON & LOGO -->
                                    <div class="step-container"
                                         v-bind:key="`tabStep2`"
                                         :class="isTabActive(2)">
                                        <div class="row">

                                            <div class="col-6">
                                                <template v-for="(inputField, index) in fieldSet">
                                                    <div v-if="inputField.fieldType == 'DropzoneField' && inputField.field == 'imgLogo'"
                                                         v-bind:key="inputField.field">

                                                        <span class="more-info" v-tooltip="$ml.get('tooltip_comp_logo')">
                                                            <font-awesome-icon icon="info-circle"/>
                                                        </span>

                                                        <dropzone-field v-if="inputField.fieldType == 'DropzoneField'" v-bind:key="index"
                                                                        :name="inputField.field"
                                                                        :id="inputField.field"
                                                                        :accept="`image/*`"
                                                                        v-model="fileTrigger">
                                                            <template v-if="images[inputField.field] && images[inputField.field]._url">
                                                                <img :src="images[inputField.field]._url"
                                                                     v-if="images[inputField.field]._url !== undefined">
                                                            </template>
                                                            <template v-else>
                                                                <div v-html="$ml.get(inputField.label)"></div>
                                                            </template>

                                                        </dropzone-field>
                                                        <hr/>
                                                    </div>

                                                    <div v-else-if="inputField.fieldType == 'SliderField' && inputField.field === 'sizeLogo'" v-bind:key="inputField.field">
                                                        <slider-field
                                                            :name="inputField.field"
                                                            :id="inputField.field"
                                                            :value="form[inputField.field]"
                                                            v-model="form[inputField.field]">
                                                            {{$ml.get(inputField.label)}}&nbsp;(%)
                                                        </slider-field>

                                                    </div>



                                                </template>
                                            </div>

                                            <div class="col-6">
                                                <span class="more-info" v-tooltip="$ml.get('tooltip_app_icon')">
                                                    <font-awesome-icon icon="info-circle"/>
                                                </span>
                                                <dropzone-field name="imgIcon"
                                                                id="imgIcon"
                                                                :accept="`image/*`"
                                                                v-model="fileTrigger">
                                                    <img :src="images.imgIcon" v-if="images.imgIcon"/>
                                                    <template v-else>
                                                        <div v-html="$ml.get('clientIconDropzone')"></div>
                                                    </template>
                                                </dropzone-field>
                                                <hr/>
                                                <div class="app-icons-holder">
                                                    <div class="ios-icon-block icon-block">
                                                        <img :src="images.imgIcon" class="ios-icon icon-img"/>
                                                        <div class="ios-icon-label">iOS Icon</div>
                                                    </div>
                                                    <div class="android-icon-block icon-block">
                                                        <img :src="images.imgIcon" class="android-icon icon-img"/>
                                                        <div class="ios-icon-label">Android Icon</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <!-- BACKGROUNDS -->
                                    <div class="step-container"
                                         v-bind:key="`sideTabStep3`"
                                         :class="isTabActive(3)"
                                        >

                                        <div class="row">
                                        <template v-for="(inputField) in fieldSet">
                                            <div class="col-6 col-portrait"
                                                 v-if="inputField.fieldType == 'DropzoneField'  &&
                                                                (inputField.field == 'imgBgFull' || inputField.field == 'imgBgTop') "
                                                 v-bind:key="inputField.field"

                                            >
                                                <span v-if="inputField.fieldType == 'DropzoneField' &&
                                                            (inputField.field == 'imgBgFull' || inputField.field == 'imgBgTop')"
                                                      class="more-info" v-tooltip="$ml.get('tooltip_background')">
                                                    <font-awesome-icon icon="info-circle"/>
                                                </span>

                                                <dropzone-field v-if="inputField.fieldType == 'DropzoneField'  &&
                                                                (inputField.field == 'imgBgFull' || inputField.field == 'imgBgTop') "

                                                                :name="inputField.field"
                                                                :id="inputField.field"
                                                                :accept="`image/*`"
                                                                v-model="fileTrigger">
                                                    <template v-if="images[inputField.field] && images[inputField.field]._url">
                                                        <img :src="images[inputField.field]._url"
                                                             v-if="images[inputField.field]._url !== undefined">
                                                    </template>
                                                    <template v-else>
                                                        <div v-html="$ml.get(inputField.label)"></div>
                                                    </template>
                                                </dropzone-field>
                                            </div>
                                            <div class="col-6"
                                                 v-else-if="filterFileds('backgroundColors', inputField.field)"
                                                 v-bind:key="inputField.field"
                                            >
                                                <color-field
                                                    v-if="inputField.fieldType == 'ColorField'"
                                                    :name="inputField.field"
                                                    :id="inputField.field"
                                                    :value="form[inputField.field]"
                                                    v-model="form[inputField.field]">
                                                    {{$ml.get(inputField.label)}}
                                                </color-field>
                                            </div>

                                        </template>
                                        </div>
                                    </div>

                                    <!-- TYPOGRAPHY -->
                                    <div class="step-container"
                                         v-bind:key="`sideTabStep4`"
                                         :class="isTabActive(4)">

                                        <div class="row">
                                            <div class="col-12">
                                                <h4>{{$ml.get('FONT_FAMILY')}}</h4>
                                            </div>
                                            <div id="font-selector" class="col-6">

                                                <Select2
                                                    v-model="form['fontFamily']"
                                                    :options="selectFonts"
                                                    :placeholder="$ml.get('FONT_FAMILY')"
                                                />
<!--                                                    :settings="{-->
<!--                                                        templateResult: companyPreview,-->
<!--                                                        templateSelection: selectionPreview,-->
<!--                                                        escapeMarkup: function(stuff){return stuff}-->
<!--                                                        }"-->
<!--                                                    @select="companyPicked($event)"-->
<!--                                                />-->
                                                <input type="hidden" :value="form['fontFamily']" name="relatedFont" />
                                            </div>
                                            <div class="col-6">
                                                <slider-field
                                                    name="baseFontSize"
                                                    id="baseFontSize"

                                                    v-model="form['baseFontSize']"
                                                    :min="10"
                                                    :max="22"
                                                    :step="1"
                                                >
                                                    {{$ml.get('FONT_SIZE')}}
                                                </slider-field>
                                            </div>
                                            <div class="col-12">
                                                <div class="space"></div>
                                                <hr/>
                                                <h4>{{$ml.get('FONT_COLORS')}}</h4>
                                            </div>
                                            <template v-for="(inputField) in fieldSet">
                                            <div class="col-6"
                                             v-if="filterFileds('typography', inputField.field)"
                                             v-bind:key="inputField.field"
                                            >
                                                <color-field
                                                    v-if="inputField.fieldType == 'ColorField'" v-bind:key="inputField.field"
                                                    :name="inputField.field"
                                                    :id="inputField.field"
                                                    :value="form[inputField.field]"
                                                    v-model="form[inputField.field]">
                                                    {{$ml.get(inputField.label)}}
                                                </color-field>
                                                <input-field
                                                    v-else-if="inputField.fieldType == 'InputField'" v-bind:key="inputField.field"
                                                    :name="inputField.field"
                                                    :id="inputField.field"
                                                    :value="form[inputField.field]"
                                                    v-model="form[inputField.field]">
                                                    {{$ml.get(inputField.label)}}
                                                </input-field>
                                                <select-field
                                                    v-else-if="inputField.fieldType == 'SelectField'" v-bind:key="inputField.field"
                                                    :name="inputField.field"
                                                    :id="inputField.field"
                                                    :value="form[inputField.field]"
                                                    v-model="form[inputField.field]">
                                                    :options="inputField.options">
                                                    {{$ml.get(inputField.label)}}
                                                </select-field>
                                                <slider-field
                                                        v-else-if="inputField.fieldType == 'SliderField'" v-bind:key="inputField.field"
                                                        :name="inputField.field"
                                                        :id="inputField.field"
                                                        :value="form[inputField.field]"
                                                        v-model="form[inputField.field]">
                                                    {{$ml.get(inputField.label)}}
                                                </slider-field>
                                                <hr v-else-if="inputField.field == 'Separator'">
                                            </div>
                                        </template>
                                        </div>

                                    </div>

                                    <!-- LOCALIZATION -->
                                    <div class="step-container"
                                         v-bind:key="`sideTabStep5`"
                                         :class="isTabActive(5)">

                                        <div class="row">
                                            <template v-for="(inputField) in fieldSet">
                                                <div class="col-6"
                                                     v-if="filterFileds('labels', inputField.field)"
                                                     v-bind:key="inputField.field"
                                                >
                                                    <color-field
                                                        v-if="inputField.fieldType == 'ColorField'" v-bind:key="inputField.field"
                                                        :name="inputField.field"
                                                        :id="inputField.field"
                                                        :value="form[inputField.field]"
                                                        v-model="form[inputField.field]">
                                                        {{$ml.get(inputField.label)}}
                                                    </color-field>
                                                    <input-field
                                                        v-else-if="inputField.fieldType == 'InputField'" v-bind:key="inputField.field"
                                                        :name="inputField.field"
                                                        :id="inputField.field"
                                                        :value="form[inputField.field]"
                                                        v-model="form[inputField.field]">
                                                        {{$ml.get(inputField.label)}}
                                                    </input-field>
                                                    <select-field
                                                        v-else-if="inputField.fieldType == 'SelectField'" v-bind:key="inputField.field"
                                                        :name="inputField.field"
                                                        :id="inputField.field"
                                                        :value="form[inputField.field]"
                                                        v-model="form[inputField.field]">
                                                        :options="inputField.options">
                                                        {{$ml.get(inputField.label)}}
                                                    </select-field>
                                                    <hr v-else-if="inputField.field == 'Separator'">
                                                </div>
                                            </template>
                                        </div>

                                    </div>

                                    <!-- COMPANY INFO -->
                                    <div class="step-container"
                                         v-bind:key="`sideTabStep6`"
                                         :class="isTabActive(6)">
                                        <div class="row">
                                            <div class="col-12 mb-3">
                                                <input-field :id="`name`"
                                                             :name="'name'"
                                                             :value="companyForm.name"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.name">{{ $ml.get('COMPANY_NAME') }}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`billingAddress`"
                                                             :name="'billingAddress'"
                                                             :value="companyForm.billingAddress"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.billingAddress">{{ $ml.get('BILLING_ADDRESS') }}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`vatNumber`"
                                                             :name="'vatNumber'"
                                                             :value="companyForm.vatNumber"
                                                             :required="false"
                                                             v-model="companyForm.vatNumber">{{ $ml.get('VAT_NUMBER') }}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`responsible`"
                                                             :name="'responsible'"
                                                             :value="companyForm.responsible"
                                                             :required="false"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.responsible">{{ $ml.get('RESPONSIBLE') }}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`billingEmail`"
                                                             :name="'billingEmail'"
                                                             :value="companyForm.billingEmail"
                                                             :required="true"
                                                             :ext-validator="emailValidator"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.billingEmail">{{ $ml.get('EMAIL') }}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`telephone`"
                                                             :name="`telephone`"
                                                             :value="companyForm.telephone"
                                                             :required="false"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.telephone">{{ $ml.get('TELEPHONE') }}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`website`"
                                                             :name="'website'"
                                                             :value="companyForm.website"
                                                             :required="false"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.website">{{ $ml.get('WEBSITE') }}
                                                </input-field>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- OFFICE ADDRESS -->
                                    <div class="step-container"
                                         v-bind:key="`sideTabStep7`"
                                         :class="isTabActive(7)">
                                        <div class="row">
                                            <div class="col-12 mb-5">
                                                <input-field :id="`office-label`"
                                                             :name="'office-label'"
                                                             :value="officeAddressForm.officeLabel"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.officeLabel">{{$ml.get('ADDRESS_LABEL')}}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`office-address`"
                                                             :name="'office-address'"
                                                             :value="officeAddressForm.street"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.street">{{$ml.get('STREET_ADDRESS')}}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`office-phone`"
                                                             :name="'office-phone'"
                                                             :value="officeAddressForm.officePhone"
                                                             v-model="officeAddressForm.officePhone">{{$ml.get('OFFICE_PHONE')}}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`office-city`"
                                                             :name="'office-city'"
                                                             :value="officeAddressForm.city"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.city">{{$ml.get('CITY')}}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`office-zip`"
                                                             :name="'office-zip'"
                                                             :value="officeAddressForm.zip"
                                                             :ext-validator="zipCodeValidator"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.zip">{{$ml.get('ZIP')}}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`office-state`"
                                                             :name="'office-state'"
                                                             :value="officeAddressForm.state"
                                                             v-model="officeAddressForm.state">{{$ml.get('STATE')}}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`office-country`"
                                                             :name="'office-country'"
                                                             :value="officeAddressForm.country"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.country">{{$ml.get('COUNTRY')}}
                                                </input-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div v-if="hasCompany" class="close-control">
                                        <button-field @click="closeView()"
                                                      circle
                                                      outline
                                                      activated
                                                      v-tooltip="$ml.get('CLOSE')"
                                                      icon="times"
                                                      class="table-action-btn">
                                        </button-field>
                                    </div>

                                    <ul class="v-tabs hPhone">
                                        <li class="v-tab-item"
                                            :class="isTabActive(1)"
                                            @click="setTab(1)"
                                        >1. Card template</li>
                                        <li class="v-tab-item"
                                            :class="isTabActive(2)"
                                            @click="setTab(2)"
                                        >2. App Icon & Logo</li>
                                        <li class="v-tab-item"
                                            :class="isTabActive(3)"
                                            @click="setTab(3)"
                                        >3. Backgrounds</li>
                                        <li class="v-tab-item"
                                            :class="isTabActive(4)"
                                            @click="setTab(4)"
                                        >4. Typography</li>
                                        <li class="v-tab-item"
                                            :class="isTabActive(5)"
                                            @click="setTab(5)"
                                        >5. Labels & Localization</li>
                                        <li class="v-tab-item"
                                            :class="isTabActive(6)"
                                            @click="setTab(6)"
                                        >6. Company information</li>
                                        <li class="v-tab-item"
                                            :class="isTabActive(7)"
                                            @click="setTab(7)"
                                        >7. Primary office address</li>
                                    </ul>
                                    <div class="wizzard-buttons">
                                        <button-field
                                            v-if="curActiveTab>1"
                                            color="primary"
                                            icon="arrow-left"
                                            @click="backClicked()"
                                            type="submit"
                                            block>
                                            Back
                                        </button-field>
                                        <button-field
                                            v-if="curActiveTab == 1 && hasCompany"
                                            color="gray"
                                            @click="closeView()"
                                            type="submit"
                                            block>
                                            Cancel
                                        </button-field>
                                        <button-field
                                            v-if="curActiveTab<7"
                                            color="secondary"
                                            icon="arrow-right"
                                            @click="nextClicked()"
                                            type="submit"
                                            :disabled="isFormDisabled"
                                            block>
                                            Next
                                        </button-field>
                                        <button-field
                                            v-if="curActiveTab === 7"
                                            color="secondary"
                                            icon="save"
                                            @click="createClient()"
                                            type="submit"
                                            :disabled="loading"
                                            :loading="loading"
                                            block>
                                            Create client
                                        </button-field>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


                    <!-- FALLBACK-->
                    <div v-if="isStepActive(2)"  class="step-container"
                         v-bind:key="`tabStep5`"
                         :class="isStepActive(2)">
                        <form v-if="form" :action="`${apiUrl}cards/preview/?pr=1&d=0&t=${selectedTemplateSlug}`"
                              method="post"
                              target="cardPreview"
                              ref="cardPreview">
                            <input type="hidden" name="preview_data" value="1"/>
                            <div class="row">

                                <template v-for="(inputField, index) in fieldSet">
                                    <div :class="inputField.col" v-bind:key="index">
                                        <span v-if="inputField.fieldType == 'DropzoneField' &&
                                                    (inputField.field == 'imgBgFull' || inputField.field == 'imgBgTop')"
                                              class="more-info" v-tooltip="$ml.get('tooltip_background')">
                                            <font-awesome-icon icon="info-circle"/>
                                        </span>
                                        <span v-else-if="inputField.fieldType == 'DropzoneField'"
                                              class="more-info" v-tooltip="$ml.get('tooltip_comp_logo')">
                                                    <font-awesome-icon icon="info-circle"/>
                                        </span>
                                        <dropzone-field v-if="inputField.fieldType == 'DropzoneField'" v-bind:key="index"
                                                        :name="inputField.field"
                                                        :id="inputField.field"
                                                        :accept="`image/*`"
                                                        v-model="fileTrigger">
                                            <template v-if="images[inputField.field] && images[inputField.field]._url">
                                                <img :src="images[inputField.field]._url"
                                                     v-if="images[inputField.field]._url !== undefined">
                                            </template>
                                            <template v-else>
                                                <div v-html="$ml.get(inputField.label)"></div>
                                            </template>
                                        </dropzone-field>
                                        <slider-field
                                            v-else-if="inputField.fieldType == 'SliderField'" v-bind:key="index"
                                            :name="inputField.field"
                                            :id="inputField.field"
                                            :value="form[inputField.field]"
                                            v-model="form[inputField.field]">
                                            {{$ml.get(inputField.label)}}&nbsp;(%)
                                        </slider-field>
                                        <color-field
                                            v-else-if="inputField.fieldType == 'ColorField'" v-bind:key="index"
                                            :name="inputField.field"
                                            :id="inputField.field"
                                            :value="form[inputField.field]"
                                            v-model="form[inputField.field]">
                                            {{$ml.get(inputField.label)}}
                                        </color-field>
                                        <input-field
                                            v-else-if="inputField.fieldType == 'InputField'" v-bind:key="index"
                                            :name="inputField.field"
                                            :id="inputField.field"
                                            :value="form[inputField.field]"
                                            v-model="form[inputField.field]">
                                            {{$ml.get(inputField.label)}}
                                        </input-field>
                                        <select-field
                                            v-else-if="inputField.fieldType == 'SelectField'" v-bind:key="index"
                                            :name="inputField.field"
                                            :id="inputField.field"
                                            :value="form[inputField.field]"
                                            v-model="form[inputField.field]">
                                            :options="inputField.options">
                                            {{$ml.get(inputField.label)}}
                                        </select-field>
                                        <hr v-else-if="inputField.field == 'Separator'">
                                    </div>
                                </template>

                            </div>
                        </form>
                    </div>


                </div>
            </div>
        </div>
        <!-- NAV BUTTONS -->
        <div class="qf-wizzard-controls" v-if="false">
            <div class="row justify-content-center">
                <div class="col-4">
                    <button-field
                        v-if="btnActiveStep>1"
                        color="secondary"

                        @click="backClicked()"
                        type="submit"
                        block>
                        Back
                    </button-field>
                </div>
                <div class="col-4">
                    <button-field
                        v-if="btnActiveStep == 1"
                        color="gray"
                        @click="closeView()"
                        type="submit"
                        block>
                        Cancel
                    </button-field>
                </div>
                <div class="col-4">
                    <button-field
                        v-if="btnActiveStep<4"
                        color="primary"
                        @click="nextClicked()"
                        type="submit"
                        :disabled="isFormDisabled"
                        block>
                        Next
                    </button-field>
                    <button-field
                        v-if="btnActiveStep === 4"
                        color="primary"
                        @click="createClient()"
                        type="submit"
                        block>
                        Create client
                    </button-field>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Select2 from 'v-select2-component';
import Parse from "parse";
import store from "@/store";

export default {
    name: "clientWizard",
    components: {
        Select2,
    },
    data() {

        return {
            hasCompany: false,
            isAdmin: false,
            loading: false,
            curUser: {},

            curActiveStep: 1,
            curActiveTab: 1,
            btnActiveStep: 1,
            apiUrl: process.env.VUE_APP_BASE_API_URL,
            editedCompany: {id:null},

            fileTrigger: {},
            images: {
                imgLogo: null,
                imgBgTop: null,
                imgBgFull: null,
                imgIcon: process.env.VUE_APP_BASE_API_URL+'/img/icon.png'
            },
            imgFiles: {
                imgLogo: null,
                imgBgTop: null,
                imgBgFull: null,
                imgIcon: null
            },

            form: {
                fontFamily: 'Alef',
                baseFontSize: 16
            },
            cardConfig: {
                attributes: {
                    imgIcon: process.env.VUE_APP_BASE_API_URL+'/img/icon.png'
                }
            },

            officeAddressForm: {
                street: '',
                officeLabel: '',
                officePhone: '',
                city: '',
                zip: '',
                state: '',
                country: '',
            },

            companyForm: {
                name:'',
                billingAddress:'',
                vatNumber:'',
                responsible:'',
                billingEmail:'',
                telephone:'',
                website: ''
            },

            selectedTemplate:  {},
            cardDesign: {
                selectedTemplateName: 'XX_ecobcard',
            },

            errors: [],
            infoBox1Visible: false,
            iconTooltip: '<div class="custom-stuff">' +
                            '<h4>This will be the icon that shows <br>on the device of each employee.</h4>' +
                            '<p>Use a 512 x 512 PNG image</p>' +
                         '</div>',
            logoTooltip: '<div class="custom-stuff">' +
                            '<h4>The logo that will appear on the <br> business card design.</h4>' +
                            '<p>Use a PNG or an SVG image</p>' +
                         '</div>',
            steps: [
                {
                    label: 'Company information',
                    slot: 'page1',
                },
            ],
        };
    },
    mounted() {
        this.curUser = Parse.User.current();

        if(this.curUser && this.curUser.attributes){
            this.isAdmin = this.curUser.attributes.isAdmin;
            if(this.curUser.attributes.company){
                this.hasCompany = true;
            }
        }

        this.$store.dispatch('getCardTemplates');
        this.$store.dispatch('getInitialTemplate');
        this.$store.dispatch('getFontFamilies');

    },
    methods: {

        filterFileds(section, fieldName) {
            let allowField = false;

            let bgFields = [
                'colorBgTop',
                'colorBgInfo',
                'colorBgBottom',
                'colorBgGradient1',
                'colorBgGradient2',
            ];
            let fontFields = [
                'colorName',
                'colorTitle',
                'sizeTitle',
                'colorInfo',
                'colorButton',
                'colorButtonText',
                //'Separator',
                'colorReady'
            ];
            let labelFields = [
                'labelPhone1',
                'labelPhone2',
                'labelEmail',
                'labelAddress',
               // 'Separator',
            ];

            switch (section) {
                case 'backgroundColors' :
                    allowField = Boolean( bgFields.indexOf(fieldName) != -1 )
                    break;

                case 'typography' :
                    allowField = Boolean( fontFields.indexOf(fieldName) != -1 )
                    //debugger;
                    break;

                case 'labels' :
                    allowField = Boolean(labelFields.indexOf(fieldName) != -1 )
                    break;
            }

            return allowField;

        },

        createClient(){
            let self = this;
            if (!this.loading) {

                self.loading = true;

                self.form.cardTemplate = self.selectedNewTemplate.id;

                self.form.appIcon = self.imgFiles.imgIcon;
                self.form.imgFiles = self.imgFiles

                self.companyForm.logo = self.imgFiles.imgIcon;

                if(!self.hasCompany && self.curUser.attributes.canSetupAgency){
                    self.companyForm.isAgency = true;
                }

                //Crient client company

                self.$store.dispatch('saveClient', {id: null, ...self.companyForm}).then((newClient) => {

                    self.$store.commit('setCardCompany', newClient);

                    //Once new client is available, use it to setup
                    let compPointer = {"__type": "Pointer", "className": "Company", "objectId": newClient.id}

                    if(!self.hasCompany){
                        newClient.set('clientOf', compPointer);
                        newClient.save();
                        self.curUser.set('company', compPointer);
                    }

                    self.curUser.save().then(()=>{

                        self.$store.commit('setUser', self.curUser);

                        self.officeAddressForm.compId = newClient.id;

                        self.form.officeAddress = {};
                        self.form.officeAddress.compId = newClient.id;

                        self.officeAddressForm.website = self.companyForm.website;

                        self.$store.dispatch('saveOfficeAddress', {id: null, ...self.officeAddressForm})
                            .then(() => {
                                //console.log("New office address is: ", newOfficeAddress);

                                self.$store.dispatch('createCardConfig', {...self.form}).then(function () {

                                    //console.log("New cardConfig is: ", newCardConfig);

                                    self.$store.dispatch('changeImpersonation', newClient).then(function () {
                                        //console.log("current user: ", self.$store.state.user.get('company'))
                                       // debugger;

                                        self.$store.dispatch('getCompany').then(() => {
                                            store.dispatch('getAgencyClients').then(() => {
                                                store.dispatch('getCardConfig').then((resp) => {

                                                    store.commit('setActiveTemplate', resp.attributes.template);

                                                    self.loading = false;
                                                    self.$notify({
                                                        group: 'notification',
                                                        type: 'success',
                                                        text: self.$ml.get('notification_success_saved'),
                                                    });

                                                    self.navToStart();
                                                })
                                            })
                                        });
                                    });
                                });
                            })
                            .catch(() => {
                                self.$notify({
                                    group: 'notification',
                                    type: 'error',
                                    title: this.$ml.get('notification_error_title'),
                                    text: this.$ml.get('notification_error_general'),
                                });
                                //self.$checkTokenExp(e);
                                self.loading = false;
                            });

                    });






                });
            }
        },
        refreshPreview(fast) {
            clearTimeout(this.timeout);
            if(fast){
                this.$refs.cardPreview.submit();
            }else{
                this.timeout = setTimeout(() => {
                    if(this.$refs.cardPreview)
                        this.$refs.cardPreview.submit();
                }, 300);
            }
        },
        selectCard(templateName, templateObject){
            this.$store.commit('setActiveNewTemplate', templateObject);
            this.selectedTemplateId = templateObject.id;
            //this.selectedTemplateSlug = templateName;
        },
        closeView(){
            this.$router.push({name: 'clients'});
        },
        setStep(stepTo) {
            let self = this;
            this.curActiveTab = 0;
            setTimeout(function(){
                self.curActiveTab = stepTo;
                self.btnActiveStep = stepTo;
            }, 200);
        },
        setTab(stepTo) {
            let self = this;
            this.curActiveTab = 0;
            setTimeout(function(){
                self.curActiveTab = stepTo;
                self.btnActiveTab = stepTo;
            }, 0);
        },
        isStepActive(stepNum) {
            if (stepNum === this.curActiveStep) {
                return 'active-step';
            } else {
                return '';
            }
        },
        isTabActive(stepNum) {
            if (stepNum === this.curActiveTab) {
                return 'active-tab';
            } else {
                return '';
            }
        },
        showMoreInfo() {
            this.infoBox1Visible = true;
        },
        validationCollector(inputValidation) {
            Array.prototype.remove = function () {
                var what, a = arguments, L = a.length, ax;
                while (L && this.length) {
                    what = a[--L];
                    while ((ax = this.indexOf(what)) !== -1) {
                        this.splice(ax, 1);
                    }
                }
                return this;
            };

            if (inputValidation.valid) {
                this.errors.remove(inputValidation.name);
            } else {
                if (this.errors.indexOf(inputValidation.name) === -1) {
                    this.errors.push(inputValidation.name);
                }
            }
        },
        nextClicked() {
            this.setStep(this.curActiveTab+1)
        },
        backClicked() {
            this.setStep(this.curActiveTab-1)
        },
        navToStart() {
            if(this.isAdmin) {
                this.$router.push({name: 'adminCards'});
            }else {
                this.$router.push({name: 'home'});
            }
        }
    },
    computed: {
        // hasBGField() {
        //     let hasField = false;
        //     if(this.fieldSet){
        //         this.fieldSet.forEach(function(inputField){
        //             if (inputField.field == 'imgBgTop' || inputField.field == 'imgBgTop' ) {
        //                 hasField = true;
        //             }
        //         });
        //     }
        //     return hasField;
        // },

        selectFonts(){
            let selectData = [];
            if(this.allFontFamilies){
                this.allFontFamilies.forEach(function(oneFont){
                    let oneLine = {
                        id: oneFont.id,
                        text:  oneFont.attributes.name,
                        fullData: oneFont.attributes,
                    }
                    selectData.push(oneLine);
                });
            }
            return selectData;
        },

        allFontFamilies() {
            return this.$store.state.fontFamilies;
        },
        fieldSet() { //fields for currently selected template
            return this.selectedNewTemplate.attributes.availableParams;
        },
        selected(){
            return this.$store.state.cardCompany;
        },
        selectedNewTemplate(){
            let template = this.$store.state.selectedNewTemplate.id ? this.$store.state.selectedNewTemplate  : this.$store.state.initialTemplate;
            return template;
        },
        selectedTemplateSlug(){
            let slug = this.$store.state.selectedNewTemplate.attributes.nice_name ? this.$store.state.selectedNewTemplate.attributes.nice_name  : this.$store.state.initialTemplate.attributes.nice_name;
            return slug;
        },
        // cardConfig() {
        //     //console.log(this.$store.state.cardConfig);
        //     //this.localCardConfig = this.$store.state.cardConfig;
        //     return this.$store.state.cardConfig;
        // },
        isFormDisabled(){
            return (this.errors.length > 0 && this.curActiveStep > 2);
        },
        availableCards() {
            return this.$store.state.cardTemplates;
        },
        emailValidator() {
            return function (value) {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            }
        },
        vatValidator() {
            return function (value) {
                let re = /(AT?U[0-9]{8}$|BE?0[0-9]{9}$|BG?[0-9]{9,10}$|HR?[0-9]{11}$|CY?[0-9]{8}[A-Z]$|CZ?[0-9]{8,10}$|DE?[0-9]{9}$|DK?[0-9]{8}$|EE?[0-9]{9}$|EL?[0-9]{9}$|ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$|FI?[0-9]{8}$|FR?[0-9A-Z]{2}[0-9]{9}$|GB?([0-9]{9}([0-9]{3}?|[A-Z]{2}[0-9]{3}))$|HU?[0-9]{8}$|IE?[0-9]{7}[A-Z]{1,2}$|IE?[0-9][A-Z][0-9]{5}[A-Z]$|IT?[0-9]{11}$|LT?([0-9]{9}|[0-9]{12})$|LU?[0-9]{8}$|LV?[0-9]{11}$|MT?[0-9]{8}$|NL?[0-9]{9}B[0-9]{2}$|PL?[0-9]{10}$|PT?[0-9]{9}$|RO?[0-9]{2,10}$|SE?[0-9]{12}$|SI?[0-9]{8}$|SK?[0-9]{10})$/;
                let result = re.test(String(value));
                return result;
            };
        },
        zipCodeValidator() {
            return function (value) {
                let re = /[\w]*[\w-]*[\w ]*\d{4,}/;
                return re.test(value);
            }
        },
    },
    watch: {
        fieldSet: {
            deep: true,
            handler(newVal){

                let self = this;
                let selectedTemplate = this.$store.state.selectedNewTemplate;
                let formFields = {};
                if(newVal){
                    newVal.forEach(function(oneField){
                        switch (oneField.field) {
                            case "imgLogo":
                                self.images.imgLogo = self.images.imgLogo ? self.images.imgLogo : self.apiUrl+'img/icon.png';
                                break;

                            case "imgBgTop":
                                self.images.imgBgTop = self.cardConfig.attributes.imgBgTop ? self.cardConfig.attributes.imgBgTop :  {_url: null};
                                break;

                            case "imgBgFull":
                                self.images.imgBgFull = self.cardConfig.attributes.imgBgFull ? self.cardConfig.attributes.imgBgFull : {_url: null};
                                break;

                            case "Separator":
                                break;

                            default:
                                if(self.$store.state.dashboardModalComponent.data && self.$store.state.dashboardModalComponent.data.useDef){
                                    formFields[oneField.field] =  oneField.defaultValue;
                                }else{
                                    formFields[oneField.field] =  self.cardConfig.attributes[oneField.field] ? self.cardConfig.attributes[oneField.field]: oneField.defaultValue;
                                }
                        }
                    });
                }

                this.form = formFields;
                this.form.logo = this.cardCompany ? this.cardCompany.attributes.logo._url : null;
                //this.form.baseFontSize = '16';
                //debugger;

                if(!this.curSettings){
                    this.curSettings = {...formFields};
                    this.initialTemplate = Object.assign({}, selectedTemplate);
                    this.initialTemplate.attributes = selectedTemplate.attributes;
                }

            }
        },
        form: {
            handler(newVal) {
                this.refreshPreview();
                //Form loading new values, check if promise resolved
                if(newVal.sizeLogo) {
                    //Form changed because of external factor -> allow revert
                    if(this.revertClicked){
                        this.revertDisabled = true;
                        this.revertClicked = false;
                    } else {
                        this.revertDisabled = false;
                    }

                }

            },
            deep: true,
        },
        fileTrigger: {
            handler(newVal) {
                if(newVal){
                    this.imgFiles[newVal.field] = newVal.file;
                    this.images[newVal.field] = newVal.url;
                    this.defaultsClicked = 0;
                    this.refreshPreview();
                }
            },
            deep: true,
        },
        // imgFiles: {
        //
        // },
    }
}
</script>

<style scoped>

.space {
    width: 100%;
    height: 20px;
    display: block;
}

.select2-container{
    width: 100% !important;
}

.step-container {
    display: none;
}

.active-tab,
.active-step {
    display: block;
}

.col-portrait .dropzone-container {
    height: 520px;
}

.close-control {
    position: absolute;
    right: -10px;
    top: -20px;
}

.hPhone {
    height: 536px;
}
.qf-wizzard-controls,.qf-wizzard-tabs {
    margin: 20px auto;
    width: 1030px;
}

.step-templates {
    height: 536px;
    overflow-y: scroll;
    padding-top: 5px;
}

.step-container {
   max-width: 880px;
    margin: 0 auto;
    width: 100%;
}

ul.qf-wizzard-tabs {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
}

.qf-one-tab.active-step {
    border-bottom: 2px solid var(--color-primary);
    color: var(--color-primary);
}

.qf-wizzard-tabs li {
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: var(--color-dark);
    padding: .2em .8em;
    border-bottom: 2px solid transparent;
    border-bottom-color: transparent;
    -webkit-transition: color .2s, border-color .2s;
    transition: color .2s, border-color .2s;
    cursor: pointer;
}

.qf-popOver-box {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    top: 0;
    left: 0;
    text-align: center;
}

.qf-wizzard-container {
    overflow-y: scroll;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 99999;
    background: #fff;
    left: 0;
    padding: 30px;
    justify-content: center;
    align-items: center;
}

.qf-wizzard-top-controls {
    position: absolute;
    right: 30px;
}
.qf-wizzard-flex {
    width: 1220px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    min-height: 536px;
}
.wizzard-tab-phone {
    min-width: 340px;
}

.wizzard-tab-content {
    width: 100%;
    display: flex;
    align-items: center;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to
{
    opacity: 0;
}


/* IPHONE STUFF */
.wrapper-phone.template {
    cursor: pointer;
    box-sizing: content-box;
    padding-bottom: 25px;
    width: 108px;
    height: 220px;
    margin: 15px;
}
.wrapper-phone.iphone.template img{
    top: 4px;
    left: 5px;
    width: 195px;
    height: auto;
    border-radius: 20px;
}
.wrapper-phone.iphone.template:after {
    background-size: 108px;
}
.wrapper-phone.template h3 span {
    background: #2b2b30;
    padding: 4px 10px 3px;
    border-radius: 50px;
    color: #fff;
}
.wrapper-phone.template h3 {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
    font-size: 13px;
    display: flex;
    justify-content: center;
}
.cards-grid{
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
}
.card-designs {
    transition: all .3s ease;
}
.right-modal + .eco-content-container .card-designs{
    margin: 0 30vw 0 auto;
}
cards-grid{
    display: flex;
    flex-wrap: wrap;
}
.card-designs {
    transition: all .3s ease;
}
.right-modal + .eco-content-container .card-designs{
    margin: 0 30vw 0 auto;
}
/*
.cards-grid:not(.active),
.cards-grid.active ~ .card-designs{
    display: none;
}
*/
.wrapper-phone {
    width: 432px;
    height: 878px;
    overflow: hidden;
    position: relative;
    box-sizing: content-box;
    width: 216px;
    height: 439px;
    margin: 0 auto;
}
.iphone:after {
    content: '';
    background-image: url('../../assets/images/iphone-wrapper.png');
    background-repeat: no-repeat;
    background-size: 216px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}
.iphone.template::after {
    pointer-events: auto;
}
.iphone > iframe,
.iphone img{
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    box-sizing: content-box;
    top: 9px;
    left: 11px;
    width: 387px;
    height: 834px;
    /*background: #000;*/
}
.sc06.wrapper-phone{
    width: 262px;
    height: 536px;
}
.sc06.iphone:after{
    background-size: 260px;
}
.sc06.iphone > iframe,
.sc06.iphone img{
    transform: scale(0.6);
    top: 11px;
    left: 14px;
    width: 386px;
    height: 836px;
}
.template > svg {
    position: absolute;
    z-index: 2;
    font-size: 48px;
    color: var(--color-secondary);
    top: calc(50% - 24px - 20px);
    right: calc(50% - 24px);
    background: #fff;
    border-radius: 50%;
    border: 3px solid #ffffff;
    display: block;
}
.iphone::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 3px solid #efa633;
    border-right: 3px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 0;
}
@keyframes spinner {
    to {
        transform: rotate(360deg)
    }
}
ul.v-tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    border-left: 3px solid #f1f1f1;
}

li.v-tab-item {
    cursor: pointer;
    border-left: 3px solid transparent;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 14px;
    margin-left: -3px;
}

li.v-tab-item.active-tab {
    border-color: #eea632;
}
.wizzard-buttons {
    padding: 0 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: 20px;
}

.wizzard-buttons button + button {
    margin: 10px 0 0 !important;
}
.qf-wizzard.h100 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    padding: 30px 0;
}


.step-templates::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.step-templates::-webkit-scrollbar-track {
  background: none;        /* color of the tracking area */
}

.step-templates::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid var(--color-primary);  /* creates padding around scroll thumb */
}


.step-templates {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: var(--color-primary) rgba(0,0,0,0);   /* scroll thumb and track */
}

</style>
