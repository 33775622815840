<template>
    <div id="dashboard" class="eco-container-fluid h100">
        <div class="bg-white h100 relative" v-if="userCompany !== null || curUser ">
            <dashboard-layout-header></dashboard-layout-header>
            <admin-nav v-if="isInAdmin"></admin-nav>
            <dashboard-layout-nav v-else></dashboard-layout-nav>
            <dashboard-right-modal></dashboard-right-modal>
            <div class="eco-content-container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardLayoutNav from './LayoutNav'
    import AdminNav from './../admin/AdminNav'
    import DashboardLayoutHeader from './LayoutHeader'
    import DashboardRightModal from './RightModal'
    import Parse from 'parse';
    export default {
        name: "DashboardLayout",
        components: {
            DashboardLayoutNav,
            DashboardLayoutHeader,
            DashboardRightModal,
            AdminNav
        },
        computed: {
            userCompany() {
                return this.$store.state.userCompany
            },
            isInAdmin(){
                if(this.$route.path.includes('admin/')){
                    return true;
                }else {
                    return false;
                }
            },
        },
        data() {
            return {
                curUser: {},
            }
        },
        created() {
            let self = this;
            this.curUser = Parse.User.current();

            if(this.curUser && this.curUser.attributes.company){
                let promises = [
                    self.$store.dispatch('getCardTemplates'),
                    self.$store.dispatch('getCardConfig'),
                    self.$store.dispatch('getCompany'),
                ];
                self.$store.dispatch('currentUser').then(function(){
                    Promise.all(promises)
                        .then()
                        .catch(function(err){
                            self.$checkTokenExp(err);
                        });
                });
            }
        },
        methods: { }
    }
</script>

<style scoped>

</style>
