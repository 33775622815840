<template>
    <div class="eco-content-scroll">
        <div class="eco-content-space mh100">
            <div class="row">
                <!-- CUSTOMIZATION FIELDS -->

                <div class="col-4">
                    <form :action="`${apiUrl}cards/preview/?pr=1&d=${defaultsClicked}&comp=${cardCompany.id}&t=${selectedTemplateSlug}`"
                          method="post"
                          target="cardPreview"
                          ref="cardPreview">
                        <input type="hidden" name="preview_data" value="1"/>
                        <input type="hidden" name="d" :value="this.defaultsClicked"/>
                    <div class="row">
                        <div class="col-6 relative">
                            <span class="more-info" v-tooltip="$ml.get('tooltip_app_icon')">
                                <font-awesome-icon icon="info-circle"/>
                            </span>
                            <span v-if="images.appIcon && images.appIcon._url"
                                  class="clear-control"
                                  @click="clearDropzone('appIcon')"
                            >
                                <font-awesome-icon icon="times"/>
                            </span>
                            <dropzone-field name="appIcon"
                                            id="appIcon"
                                            :accept="`image/*`"
                                            v-model="images.DropzoneField">
                                <template v-if="images.appIcon && images.appIcon._url">
                                    <img :src="images.appIcon._url">
                                </template>
                                <template v-else>
                                    <div v-html="$ml.get('clientIconDropzone')"></div>
                                </template>

                            </dropzone-field>
                        </div>

                        <template v-for="(inputField, index) in fieldSet">
                            <div :class="inputField.col" v-bind:key="index">
                                <!-- TO-DO: Proper functionality to clear image controls -->
                                <span v-if="false && inputField.fieldType == 'DropzoneField' && images[inputField.field] && images[inputField.field]._url"
                                      class="clear-control"
                                      @click="clearDropzone(inputField.field)"
                                >
                                    <font-awesome-icon icon="times"/>
                                </span>
                                <span v-if="inputField.fieldType == 'DropzoneField' &&
                                            (inputField.field == 'imgBgFull' || inputField.field == 'imgBgTop')"
                                      class="more-info" v-tooltip="$ml.get('tooltip_background')">
                                    <font-awesome-icon icon="info-circle"/>
                                </span>
                                <span v-else-if="inputField.fieldType == 'DropzoneField'"
                                      class="more-info" v-tooltip="$ml.get('tooltip_comp_logo')">
                                    <font-awesome-icon icon="info-circle"/>
                                </span>

                                <dropzone-field v-if="inputField.fieldType == 'DropzoneField'" v-bind:key="index"
                                                :name="inputField.field"
                                                :id="inputField.field"
                                                :accept="`image/*`"
                                                v-model="images[inputField.fieldType]">
                                    <template v-if="images[inputField.field] && images[inputField.field]._url">
                                        <img :src="images[inputField.field]._url"
                                             v-if="images[inputField.field]._url !== undefined">
                                    </template>
                                    <template v-else>
                                        <div v-html="$ml.get(inputField.label)"></div>
                                    </template>
                                </dropzone-field>
                                <div
                                    id="font-selector"
                                    v-else-if="inputField.fieldType == 'FontFamily'" v-bind:key="index"
                                >
                                    <Select2
                                            v-model="form['relatedFont']"
                                            :options="selectFonts"
                                            :placeholder="$ml.get(inputField.label)"/>
                                    <input type="hidden" :value="form['relatedFont']" name="relatedFont" />
                                </div>
                                <slider-field
                                        v-else-if="inputField.fieldType == 'SliderField'" v-bind:key="index"
                                        :name="inputField.field"
                                        :id="inputField.field"
                                        :value="form[inputField.field]"
                                        :min="parseInt(inputField.min)"
                                        :max="parseInt(inputField.max)"
                                        v-model="form[inputField.field]">
                                    {{$ml.get(inputField.label)}}
                                </slider-field>
                                <color-field
                                        v-else-if="inputField.fieldType == 'ColorField'" v-bind:key="index"
                                        :name="inputField.field"
                                        :id="inputField.field"
                                        :value="form[inputField.field]"
                                        v-model="form[inputField.field]">
                                    {{$ml.get(inputField.label)}}
                                </color-field>
                                <input-field
                                        v-else-if="inputField.fieldType == 'InputField'" v-bind:key="index"
                                        :name="inputField.field"
                                        :id="inputField.field"
                                        :value="form[inputField.field]"
                                        v-model="form[inputField.field]">
                                    {{$ml.get(inputField.label)}}
                                </input-field>
                                <select-field
                                        v-else-if="inputField.fieldType == 'SelectField'" v-bind:key="index"
                                        :name="inputField.field"
                                        :id="inputField.field"
                                        :value="form[inputField.field]"
                                        v-model="form[inputField.field]">
                                    :options="inputField.options">
                                    {{$ml.get(inputField.label)}}
                                </select-field>
                                <hr v-else-if="inputField.field == 'Separator'">
                            </div>
                        </template>

                    </div>
                    </form>
                    <div class="d-flex button-group-wrapper">
                        <div>
                            <button-field icon="palette" color="primary" @click="applyDefaults()">{{$ml.get('APPLY_DEFAULT_SETTINGS')}}</button-field>
                        </div>
                        <div class="button-group">
                            <button-field icon="history" color="dark" @click="revertSettings()" :disabled="this.revertDisabled">{{$ml.get('REVERT_CHANGES')}}</button-field>
                            <button-field icon="save" color="secondary" @click="submitForm()"
                                          :disabled="loading"
                                          :loading="loading">{{$ml.get('SAVE_CHANGES')}}</button-field>
                        </div>
                    </div>
                </div>


                <!-- PREVIEW iFRAME -->
                <div class="col-3">
                    <div class="card-designs">
                        <div class="wrapper-phone iphone sc06">
                            <iframe v-if="frameVisible"
                                    class="cardPreview"
                                    name="cardPreview"
                                    :src="`${apiUrl}cards/render/?pr=1&comp=${cardCompany.id}&t=${currentTemplate}&d=${useDefaults}`"  frameborder="0"></iframe>
                        </div>
                    </div>
                    <!-- APP ICONS -->
                    <div class="app-icons-holder">
                        <div class="ios-icon-block icon-block">
                            <img v-if="images.appIcon && images.appIcon._url" :src="images.appIcon._url" class="ios-icon icon-img"/>
                            <img v-else :src="defaultAppIcon" class="ios-icon icon-img"/>
                            <div class="ios-icon-label">iOS Icon</div>
                        </div>
                        <div class="android-icon-block icon-block">
                            <img v-if="images.appIcon && images.appIcon._url" :src="images.appIcon._url" class="android-icon icon-img"/>
                            <img v-else :src="defaultAppIcon" class="android-icon icon-img"/>
                            <div class="ios-icon-label">Android Icon</div>
                        </div>
                    </div>
                </div>

                <!-- LIST OF DESIGNS -->
                <div class="col-5" >
                    <div v-if="availableCards.length > 0" class="cards-grid" v-bind:class="{ active: !isPreview }">
                        <div v-for="(cardTemplate,index) in availableCards"
                             class="wrapper-phone iphone template"
                             @click="selectCard(cardTemplate.attributes.nice_name, cardTemplate)"
                             v-bind:key="index">
                            <transition enter-active-class="animated fadeIn"
                                        leave-active-class="animated fadeOut"
                                        mode="out-in">
                            <font-awesome-icon
                                v-if="cardTemplate.id==selectedTemplate.id"
                                icon="check-circle"/>
                            </transition>
                            <img v-if="cardTemplate.attributes.screenshot"
                                 :src="cardTemplate.attributes.screenshot._url" />
                            <h3 class="font-bebas-neue"><span>{{cardTemplate.attributes.name}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Select2 from 'v-select2-component';

    export default {
        name: "DashboardCardDesign",
        components: {
            Select2,
        },
        data() {
            return {
                revertDisabled: true,
                revertClicked: false,

                defaultsClicked: 0,
                useDefaults: 0,

                defaultAppIcon: process.env.VUE_APP_BASE_API_URL+'/img/icon.png',

                currentTemplate: '00_blank',

                cardStack: [],

                /* DATA FOR THE FORM */
                form: {},
                images: {
                    appIcon: {},
                    imgLogo: null,
                    imgBgTop: null,
                    imgBgFull: null,
                },
                imgFiles: {
                    appIcon: null,
                    imgLogo: null,
                    imgBgTop: null,
                    imgBgFull: null,
                },

                //TRANSITION PARAMS
                loading: false,
                timeout: null,
                isPreview: false,
                frameVisible: true,

                apiUrl: process.env.VUE_APP_BASE_API_URL,
                selectedTemplateId: this.$store.state.selectedTemplate.id

            };

        },
        mounted() {
            this.prepareCardData();
            this.$store.dispatch('getFontFamilies');
        },
        methods: {
            clearDropzone(zone){
                this.images[zone] = {};
                this.imgFiles[zone] = null;
            },
            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('saveCardConfig', {
                        id: this.id,
                        form: this.form,
                        imgFiles: this.imgFiles,
                        templateId: this.selectedTemplateId,
                        designVer: self.$store.state.cardConfig.attributes.designVer
                    }).then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });
                    }).catch((e) => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                    });
                }
            },
            prepareCardData(){
                let self = this;

                let allPromises = [
                    self.$store.dispatch('getCardTemplates'),
                    self.$store.dispatch('getCardConfig'),
                    //self.$store.dispatch('getCompany')
                ];
                Promise.all(allPromises)
                    .then(function(e){
                        self.revertDisabled = true;
                        self.revertClicked = true;
                        let curCompany = self.$store.state.cardCompany;
                        self.setupCards(e[0], e[1], curCompany);
                        if(e[1].attributes.template){
                            self.currentTemplate = e[1].attributes.template.attributes.nice_name;
                            self.$store.commit('setActiveTemplate', e[1].attributes.template);
                            self.useDefaults = 0;
                        }
                        if(e[1].attributes.appIcon){
                            self.images.appIcon = e[1].attributes.appIcon;
                        } else if(curCompany.attributes.logo){
                            self.images.appIcon = curCompany.attributes.logo;
                        }else {
                            self.images.appIcon = {};
                        }
                    })
                    .catch(function(err){
                        self.$checkTokenExp(err);
                    });

            },
            setupCards(allCards, cardConfig, currComp) {
                let filteredCards = [];

                if(currComp){
                    if(currComp.attributes.isAgency){
                        if(allCards.length > 0){
                            allCards.forEach(function(item){
                                if(!item.attributes.hidden || item.attributes.allowedFor.includes(currComp.id)){
                                    filteredCards.push(item);
                                }
                            });
                        }
                    }else{
                        if(allCards.length > 0 && cardConfig) {
                            allCards.forEach(function (item) {
                                if (item.id == cardConfig.attributes.template.id) {
                                    filteredCards.push(item);
                                }
                            });
                        }
                    }
                }

                this.cardStack = filteredCards;
            },

            selectCard(templateName, templateObject){
                if(this.cardConfig){
                    if(templateName == this.cardConfig.attributes.template.attributes.nice_name){
                        this.useDefaults = 0;
                    }
                    this.$store.commit('setActiveTemplate', templateObject);
                    this.selectedTemplateId = templateObject.id;
                    this.currentTemplate = templateName;

                    this.isPreview = !this.isPreview;
                    this.revertClicked = false;

                    //this.frameVisible = true;
                }
            },
            applyDefaults(){
                let theDefaults = this.originalParams;
                this.form = { ...theDefaults };
                this.defaultsClicked = 1;
                this.revertDisabled = false;
            },
            refreshPreview(fast) {
                //console.log("Submitting: ", this.form);

                clearTimeout(this.timeout);
                if(fast){
                    this.$refs.cardPreview.submit();
                    this.defaultsClicked = 0;
                }else{
                    this.timeout = setTimeout(() => {
                        if(this.$refs.cardPreview)
                        this.$refs.cardPreview.submit();
                        this.defaultsClicked = 0;
                    }, 300);
                }
            },
            revertSettings(){
                //this.revertClicked = true;
                this.$store.commit('setActiveTemplate', this.initialTemplate);
                this.revertClicked = true;
                this.form = { ...this.curSettings };

                this.defaultsClicked = 0;
                //this.currentTemplate = this.initialTemplate.attributes.name;
                //this.$store.commit('setActiveTemplate', this.initialTemplate);
            }
        },
        computed: {
            //TEMPLATE
            selectedTemplate(){ //template committed to store
                return this.$store.state.selectedTemplate;
            },
            selectedTemplateSlug(){
                return this.$store.state.selectedTemplate.attributes.nice_name;
            },
            fieldSet() { //fields for currently selected template
                return this.$store.state.selectedTemplate.attributes.availableParams;
            },

            //CURRENT CLIENT
            cardCompany(){
                return this.$store.state.cardCompany;
            },

            //CARD CONFIG
            cardConfig() { //config of currently selected client
                return this.$store.state.cardConfig;
            },
            originalParams(){
                let defaults = {}
                let self = this;
                if(this.fieldSet){
                    defaults.logo = this.form.logo.slice();
                    this.fieldSet.forEach(function(oneField){
                        switch (oneField.field) {
                            case "imgLogo":
                                defaults.imgLogo = self.images.imgLogo;
                                break;

                            case "imgBgFull":
                            case "imgBgTop":
                                defaults[oneField.field] = null;
                                self.images[oneField.field] = null;
                                self.imgFiles[oneField.field] = null;
                                break;
                            //
                            // case "imgBgTop":
                            //     defaults[oneField.field] = null;
                            //     self.images[oneField.field] = null;
                            //     self.imgFiles[oneField.field] = null;
                            //     break;
                            case "FontFamily":
                                defaults.relatedFont = oneField.defaultValue;
                                break;

                            case "Separator":
                                break;

                            default:
                                defaults[oneField.field] =  oneField.defaultValue;
                                break;
                        }
                    });
                }
                return defaults;
            },

            //CARD STACK
            availableCards() {
                return this.cardStack;
            },

            selectFonts(){
                let selectData = [];
                if(this.allFontFamilies){
                    this.allFontFamilies.forEach(function(oneFont){
                        let oneLine = {
                            id: oneFont.id,
                            text:  oneFont.attributes.name,
                            fullData: oneFont.attributes,
                        }
                        selectData.push(oneLine);
                    });
                }
                return selectData;
            },
            allFontFamilies() {
                return this.$store.state.fontFamilies;
            },
        },
        watch: {
            cardCompany: {
                handler(newVal, oldVal) {
                    if(newVal.id !== oldVal.id){
                        this.curSettings = null;
                        this.prepareCardData();
                    }
                },
            },
            form: {
                handler(newVal) {
                    this.refreshPreview();
                    //Form loading new values, check if promise resolved
                    if(newVal.sizeLogo) {
                        //Form changed because of external factor -> allow revert
                        if(this.revertClicked){
                            this.revertDisabled = true;
                            this.revertClicked = false;
                        } else {
                            this.revertDisabled = false;
                        }

                    }

                },
                deep: true,
            },
            images: {
                handler(data) {

                    if(data && data.DropzoneField){
                        this.imgFiles[data.DropzoneField.field] = data.DropzoneField;
                        this.images[data.DropzoneField.field]._url = data.DropzoneField.url
                        this.defaultsClicked = 0;
                        this.refreshPreview();
                    }
                },
                deep: true,
            },
            fieldSet: {
                deep: true,
                handler(newVal){
                    let self = this;
                    let selectedTemplate = this.$store.state.selectedTemplate;
                    let formFields = {};
                    if(newVal){
                        newVal.forEach(function(oneField){
                            switch (oneField.field) {
                                case "imgLogo":
                                    self.images.imgLogo = self.cardConfig.attributes.imgLogo ? self.cardConfig.attributes.imgLogo : selectedTemplate.attributes.imgLogo;
                                    //debugger;
                                    break;

                                case "imgBgTop":
                                    self.images.imgBgTop = self.cardConfig.attributes.imgBgTop ? self.cardConfig.attributes.imgBgTop :  {_url: null};
                                    break;

                                case "imgBgFull":
                                    self.images.imgBgFull = self.cardConfig.attributes.imgBgFull ? self.cardConfig.attributes.imgBgFull : {_url: null};
                                    break;

                                case "relatedFont":
                                    if(self.cardConfig.attributes.relatedFont){
                                      formFields.relatedFont = self.cardConfig.attributes.relatedFont.id;
                                    }
                                    break;

                                case "Separator":
                                    break;

                                default:
                                    if(self.$store.state.dashboardModalComponent.data && self.$store.state.dashboardModalComponent.data.useDef){
                                        formFields[oneField.field] =  oneField.defaultValue;
                                    }else{
                                        formFields[oneField.field] =  self.cardConfig.attributes[oneField.field] ? self.cardConfig.attributes[oneField.field]: oneField.defaultValue;
                                    }

                            }
                        });
                    }

                    this.form = formFields;
                    this.form.logo = this.cardCompany ? this.cardCompany.attributes.logo._url : null;

                    if(!this.curSettings){
                        this.curSettings = {...formFields};
                        this.initialTemplate = Object.assign({}, selectedTemplate);
                        this.initialTemplate.attributes = selectedTemplate.attributes;
                    }

                }
            }
        }
    }
</script>

<style scoped>
.eco-content-scroll{padding: 10px}
.eco-content-space{background: #FFFFFF;padding: 25px;}
.wrapper-phone.template {
    cursor: pointer;
    box-sizing: content-box;
    padding-bottom: 25px;
    width: 108px;
    height: 220px;
    margin: 15px;
}
.wrapper-phone.iphone.template img{
    top: 4px;
    left: 5px;
    width: 195px;
    height: auto;
    border-radius: 20px;
}
.wrapper-phone.iphone.template:after {
    background-size: 108px;
}
.wrapper-phone.template h3 span {
    background: #2b2b30;
    padding: 4px 10px 3px;
    border-radius: 50px;
    color: #fff;
}
.wrapper-phone.template h3 {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
    font-size: 13px;
    display: flex;
    justify-content: center;
}
.cards-grid{
    display: flex;
    flex-wrap: wrap;
}
.card-designs {
    transition: all .3s ease;
}
.right-modal + .eco-content-container .card-designs{
    margin: 0 30vw 0 auto;
}
/*
.cards-grid:not(.active),
.cards-grid.active ~ .card-designs{
    display: none;
}
*/
.wrapper-phone {
    width: 432px;
    height: 878px;
    overflow: hidden;
    position: relative;
    box-sizing: content-box;
    width: 216px;
    height: 439px;
    margin: 0 auto;
}
.iphone:after {
    content: '';
    background-image: url('../../assets/images/iphone-wrapper.png');
    background-repeat: no-repeat;
    background-size: 216px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}
.iphone.template::after {
    pointer-events: auto;
}
.iphone > iframe,
.iphone img{
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    box-sizing: content-box;
    top: 12px;
    left: 14px;
    width: 376px;
    height: 815px;
    /*background: #000;*/
}
.sc06.wrapper-phone{
    width: 262px;
    height: 536px;
}
.sc06.iphone:after{
    background-size: 260px;
}
.sc06.iphone > iframe,
.sc06.iphone img{
    transform: scale(0.6);
    top: 11px;
    left: 14px;
    width: 386px;
    height: 836px;
}
.template > svg {
    position: absolute;
    z-index: 2;
    font-size: 48px;
    color: var(--color-secondary);
    top: calc(50% - 24px - 20px);
    right: calc(50% - 24px);
    background: #fff;
    border-radius: 50%;
    border: 3px solid #ffffff;
    display: block;
}
.iphone::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 3px solid #efa633;
    border-right: 3px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 0;
}
@keyframes spinner {
    to {
        transform: rotate(360deg)
    }
}
.button-group-wrapper {
    flex-wrap: wrap;
    width: 100%;
}

.button-group-wrapper > div,
.button-group-wrapper button {
    width: 100%;
}

.button-group {
    display: flex;
    margin-top: 10px;
}
</style>
