<template>
    <div class="dropzone-container"
         :class="assignClasses">
        <div class="dropzone-content font-bebas-neue"
             :class="{'dropzone-hover': fileDragOver}"
             @dragenter="fileDragOver=true" @dragend="fileDragOver=false"
             @dragleave="fileDragOver=false">
            <div v-if="!imageBase64 && !fileName.length"
                 class="dropzone-text">
                <slot></slot><span v-if="required" class="asterix">*</span>
            </div>
            <div v-else-if="fileName.length"
                 class="dropzone-text">{{fileName}}
            </div>
            <img :src="imageBase64" alt="image" v-else-if="imageBase64">
            <img :src="value" alt="image" v-else>
            <input type="file"
                   :name="name"
                   :accept="accept"
                   :id="id"
                   @change="filesChange($event)">
            <input type="hidden"
                   :name="`preview-`+name"
                   :value="imageBase64"
                   v-if="imageBase64 !== null">
        </div>
    </div>
</template>

<script>
    export default {
        name: "DropzoneField",
        props: {
            name: String,
            error: {
                type: String,
                default: ''
            },
            color: {
                type: String,
                default: ''
            },
            accept: {
                type: String,
                default: '*'
            },
            id: String,
            required: {
                type: Boolean,
                default: false
            },
            extValidator: {
                type: Function,
                default: null
            }
        },
        computed: {
            assignClasses() {
                let resultClasses = '';
                if (this.error.length) {
                    resultClasses += 'color-error';
                } else {
                    switch (this.color) {
                        case 'primary':
                            resultClasses += 'color-primary';
                            break;
                        case 'secondary':
                            resultClasses += 'color-secondary';
                            break;
                        case 'error':
                            resultClasses += 'color-error';
                            break;
                        default:
                            break;
                    }
                }
                return resultClasses;
            },
        },
        data() {
            return {
                fileDragOver: false,
                imageBase64: null,
                fileName: ''
            }
        },
        methods: {
            internalValidator(fileObject) {
                let result  = false;

                if(!this.required){
                    result = true;
                }else if(fileObject || this.$attrs.value) {
                    result = true;
                }
                return result;
            },
            filesChange(event) {

                if (event.target.files[0]) {
                    let fileType = event.target.files[0].type;
                    this.fileDragOver = false;
                    if (fileType.includes('image')) {
                        this.fileName = '';
                        let reader = new FileReader();
                        let file = event.target.files[0];
                        // this.$emit('input', {name: event.target.files[0].name, file: file});
                        if (event.target.files[0]) {
                            reader.readAsDataURL(file);
                        }
                        reader.onloadend = () => {
                            this.imageBase64 = reader.result;
                            this.$emit('input', {
                                field: this.$props.name,
                                name: event.target.files[0].name,
                                file: event.target.files[0],
                                url: this.imageBase64
                            });
                        };
                    } else {
                        this.imageBase64 = null;
                        this.fileName = event.target.files[0].name;
                        this.$emit('input', {
                            field: this.$props.name,
                            name: event.target.files[0].name,
                            file: event.target.files[0],
                            url: this.imageBase64
                        });
                    }

                    this.$emit('validation-result', {'name': this.name, 'valid': this.internalValidator(event.target.files[0])});
                }
            },
        },
        mounted() {
            this.$emit('validation-result', {'name': this.name, 'valid': this.internalValidator()});
        }
    }
</script>

<style scoped>

    .asterix {
        color: var(--color-error);
    }
    .dropzone-container {
        flex: 1;
        height: 115px;
        position: relative;
        margin-bottom: 10px;
    }

    .dropzone-content {
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border: 1px dashed var(--color-grey);
        color: var(--color-dark);
        /*border-radius: 10px;*/
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-grey);
        /* transition: all 0.25s ease-in-out; */
    }

    .dropzone-content img {
        padding: 10px;
        object-fit: contain;
        display: block;
        height: 100%;
        width: 100%;
        max-width: 100%;
    }

    .dropzone-content.dropzone-hover,
    .dropzone-content:hover {
        border-color: var(--color-dark);
        color: var(--color-dark);
    }

    .dropzone-content > input[type=file] {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }

    .dropzone-text {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .color-primary .dropzone-content {
        border: 1px dashed var(--color-primary);
        color: var(--color-primary);
    }

    .color-primary .dropzone-content.dropzone-hover,
    .color-primary .dropzone-content:hover {
        border-color: var(--color-primary-dark);
        color: var(--color-primary-dark);
    }

    .color-secondary .dropzone-content {
        border: 1px dashed var(--color-secondary);
        color: var(--color-secondary);
    }

    .color-secondary .dropzone-content.dropzone-hover,
    .color-secondary .dropzone-content:hover {
        border-color: var(--color-secondary);
        color: var(--color-secondary);
    }

    .color-error .dropzone-content {
        border: 1px dashed var(--color-error);
        color: var(--color-error);
    }

    .color-error .dropzone-content.dropzone-hover,
    .color-error .dropzone-content:hover {
        border-color: var(--color-error-dark);
        color: var(--color-error-dark);
    }
    .dropzone-text span.asterix {
        position: absolute;
        top: 0;
        right: 2px;
    }
</style>
