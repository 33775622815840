<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="addNewClient()"
                                  icon="plus"
                                  color="primary">{{$ml.get('ADD_CLIENT')}}
                    </button-field>
                </div>
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name_vat_website')"
                    />
                </div>
                <!--<div class="table-top-search">-->
                    <!--{{$ml.get('Search')}}-->
                <!--</div>-->
            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                        <tr>
                            <th></th>
                            <th></th>
                            <th>{{$ml.get('COMPANY_NAME')}}</th>
                            <th>{{$ml.get('VAT_NUMBER')}}</th>
                            <th>{{$ml.get('BILLING_ADDRESS')}}</th>
                            <th>{{$ml.get('RESPONSIBLE')}}</th>
                            <th>{{$ml.get('BILLING_EMAIL')}}</th>
                            <th v-if="false">{{$ml.get('TELEPHONE')}}</th>
                            <th>{{$ml.get('CARD_COUNT')}}</th>
                            <th class="actions">{{$ml.get('ACTIONS')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in tableRows" v-bind:key="index" :class="suspendedRow(item)">
                            <td>
                                <checkbox-field :id="`selectedRow${item.id}`"
                                                :nqme="`selectedRow${item.id}`"
                                                v-model="selectedRows[item.id]"
                                                color="secondary"
                                ></checkbox-field>
                            </td>
                            <td class="hasCursor" @click="goToClientCards(item)">
                                <div class="ellipsis">
                                    <img :src="item.attributes.logo._url" class="company-logo"/>
                                </div>
                            </td>
                            <td class="hasCursor" @click="goToClientCards(item)">
                                <div class="ellipsis">{{item.attributes.name}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.attributes.vatNumber}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.attributes.billingAddress}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.attributes.responsible}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.attributes.billingEmail}}</div>
                            </td>
                            <td v-if="false">
                                <div class="ellipsis">{{item.attributes.telephone}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.employeeCount}}</div>
                            </td>
                            <td>
                                <div class="table-action-btn-gorup">
                                    <button-field :disabled="sharing"
                                                  circle
                                                  outline
                                                  @click="copySelfOnboardLink(item)"
                                                  v-tooltip="$ml.get('tooltip_onboard_link')"
                                                  icon="link"
                                                  class="table-action-btn"
                                                  color="secondary"></button-field>
                                    <button-field @click="editClient(item.id)"
                                                  circle
                                                  outline
                                                  v-tooltip="`Edit client`"
                                                  icon="edit"
                                                  class="table-action-btn"
                                                  color="primary"></button-field>
                                    <button-field @click="suspendClient(item.id)"
                                                  circle
                                                  outline
                                                  :activated="isSuspended(item)"
                                                  v-tooltip="suspendMesssage(item)"
                                                  icon="moon"
                                                  color="dark"
                                                  class="table-action-btn"></button-field>
                                    <button-field @click="deleteClient(item.id)"
                                                  circle
                                                  outline
                                                  v-tooltip="`Delete client`"
                                                  icon="trash-alt"
                                                  class="table-action-btn"
                                                  :disabled="forbidDelete(item)"
                                                  color="error"></button-field>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="table-bottom">
                <div class="table-bottom-buttons">
                    <button-field @click="bulkDelete"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">{{$ml.get('DELETE_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                </div>
                <div v-if="false" class="table-pagination">
                    <ul class="pagination">
                        <li><a href="#">« {{$ml.get('Prev')}}</a></li>
                        <li><a href="#">1</a></li>
                        <li><a class="active" href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><span>...</span></li>
                        <li><a href="#">18</a></li>
                        <li><a href="#">{{$ml.get('Next')}} »</a></li>
                    </ul>
                </div>
                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardClients",
        computed: {

            tableRows() {
                return this.$store.state.agencyClients;
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                perPageOptions: [
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                perPageValue: 25,
                selectedRows: {},
                sharing: false,
                wizzardOpened: true,
                searchString: null,
                timeout: null
            }
        },
        mounted() {
            this.$store.dispatch('getCompany').then(() => {
                this.$store.dispatch('getCardTemplates');
                this.$store.dispatch('getAgencyClients').then(() => {
                });
            });
            this.$store.commit('closeRightModal');
        },
        methods: {
            doSearch(searchString){
                this.$store.dispatch('getAgencyClients', {searchTerm : searchString})
            },
            suspendedRow(item){
                if(this.isSuspended(item)){
                    return 'suspended';
                }else{
                    return '';
                }
            },
            copySelfOnboardLink(item){

                let invitationLink = window.location.protocol + '//' + window.location.host
                    +'/addme/'+item.id+'/'+item.attributes.frameSecret;

                navigator.clipboard.writeText(invitationLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification_onboard_link_copied')
                });

            },
            suspendMesssage(item){
                if(this.isSuspended(item)){
                    return this.$ml.get('RESTORE_CLIENT');
                }else {
                    return this.$ml.get('SUSPEND_CLIENT');
                }
            },
            isSuspended(item){
                return item.attributes.suspended;
            },
            suspendClient(id){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientSuspend', data: {ids: [id]}})
            },
            forbidDelete(item){
                return item.attributes.isAgency;
            },
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            addNewClient(){
                this.$router.push({name: 'client-add'});
            },
            editClient(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsForm', data: {id: id}})
                this.wizzardOpened = true;
            },
            deleteClient(id) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsDelete', data: {ids: [id]}})
            },
            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardClientsDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
            goToClientCards(clientCompany) {
                let self = this;
                this.$store.dispatch('changeImpersonation', clientCompany).then(function(response){
                    if(response.attributes.impersonating){
                        self.$notify({
                            group: 'notification',
                            type: 'success',
                            text: "Switching to managing "+clientCompany.attributes.name+"...",
                        });
                        self.$store.commit('setCardCompany',clientCompany);
                        setTimeout(function(){
                            self.$router.push({name: 'home'});
                        }, 1500);
                    }

                });

                // if (!this.sharing) {
                //     this.sharing = true;
                //     this.$store.dispatch('shareCards', ids).then(() => {
                //         this.$notify({
                //             group: 'notification',
                //             type: 'success',
                //             text: this.$ml.get('notification_share_card'),
                //         });
                //         this.sharing = false;
                //     }).catch((e) => {
                //         this.$notify({
                //             group: 'notification',
                //             type: 'error',
                //             title: this.$ml.get('notification_error_title'),
                //             text: this.$ml.get('notification_error_general'),
                //         });
                //         this.sharing = false;
                //         self.$checkTokenExp(e);
                //     })
                // }
            },
        },
        watch : {
            searchString(newSearch) {
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
        }
    }
</script>

<style scoped>
    .per-page-select {
        width: 110px;
    }
    .hasCursor {
        cursor: pointer;
    }
    .suspended {
        opacity: 0.5;
    }
    .company-logo {
        max-height: 16px;
        max-width: 40px;
    }
</style>
