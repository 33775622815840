<template>
    <div class="eco-sidebar">
        <div class="eco-sidebar-wrapper">
            <ul class="eco-sidebar-nav font-bebas-neue">
                <li v-if="isAdmin">
                    <router-link :to="{name: 'clients'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="user-tag"/></span> {{$ml.get('CLIENTS')}}
                    </router-link>
                </li>
                <li v-if="isAdmin">
                    <router-link :to="{name: 'users'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="user-tag"/></span> {{$ml.get('USERS')}}
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="goToClients">
            <button-field icon="arrow-left" color="primary"
                          @click="navTo('home')"
            >{{$ml.get('BACK')}}</button-field>
        </div>

        <div class="version">v.{{version}}</div>

    </div>
</template>

<script>
    import Parse from 'parse';
    export default {
        name: "DashboardLayoutNav",
        data(){
          return {
              ClientPopupActive: false,
              userCompany: this.$store.state.userCompany,
              mode: process.env.VUE_APP_MODE,
              isAdmin: false,
          }
        },
        mounted() {
            let curUser = Parse.User.current();
            if(curUser && curUser.attributes){
                this.isAdmin = curUser.attributes.isAdmin;
            }
        },
        computed: {
            isInWizzard(){
              if(this.$route.name == 'client-add'){
                  return true;
              }else {
                  return false;
              }
            },
            isInClients(){
                if(this.$route.name == 'clients'){
                    return true;
                }else {
                    return false;
                }
            },
            version () {
                return process.env.VUE_APP_VERSION;
            }
        },
        methods: {
            navTo(destination) {
                if(destination == 'home' && this.isAdmin) {
                    this.$router.push({name: 'adminCards'});
                }else {
                    this.$router.push({name: destination});
                }

            }
        }
    }
</script>

<style scoped>
    .goToClients{
        margin-top: auto;
        padding: 25px 15px;
    }
    .version {
        text-align: center;
        color: #777777;
        font-size: 10px;
        position: absolute;
        bottom: 0;
        padding: 6px 16px;
    }
</style>
