import Vue from 'vue'
import VueRouter from 'vue-router'

import Authenticate from '../components/authenticate/Authenticate'
import AuthenticateLogin from '../components/authenticate/AuthenticateLogin'
import AuthenticateOnboard from '../components/authenticate/AuthenticateOnboard'
import AuthenticateForgotten from '../components/authenticate/AuthenticateForgotten'
import AuthenticateResetPassword from '../components/authenticate/AuthenticateResetPassword'

import StyleComponents from '../components/global/StyleComponents'

import DashboardLayout from '../components/layout/Dashboard'
import DashboardEmployeeCards from '../components/pages/EmployeeCards'
import DashboardCardDesign from '../components/pages/CardDesign'
import DashboardCompanyCoordinates from '../components/pages/ClientCoordinates'
import DashboardBilling from '../components/pages/Billing'
import DashboardClients from '../components/admin/ClientList'
import UserList from '../components/admin/UserList'

import addClient from "@/components/pages/ClientAdd";
import ClientInformation from "@/components/pages/ClientInformation";
import DashboardProfile from '../components/pages/UserProfile'

import AdminEmployeeCards from '../components/pages/AdminEmployeeCards'
import AdminEstimates from '../components/pages/AdminEstimates'

import store from '../store/index';
import EmployeeOnboard from "../components/onboard/EmployeeOnboard";
import ProfileSuspended from "../components/global/ProfileSuspended"

import moment from "moment";

Vue.use(VueRouter);

const routes = [
    {
        path: '/auth',
        component: Authenticate,
        beforeEnter: (to, from, next) => {
            store.dispatch('currentUser').then(() => {
                if (!store.state.isAuthenticated) {
                    next();
                } else if(store.state.user.attributes.isAdmin){
                    next({name: 'adminCards'});
                } else {
                    next('/');
                }
            }).catch(() => {
                if (!store.state.isAuthenticated) {
                    next();
                } else if(store.state.user.attributes.isAdmin){
                    next({name: 'adminCards'});
                } else {
                    next('/')
                }
            });
        },
        children: [
            {
                path: '',
                name: 'auth',
                component: AuthenticateLogin
            },
            {
                path: 'forgotten-password',
                name: 'forgottenPassword',
                component: AuthenticateForgotten
            },
            {
                path: 'password-reset',
                name: 'passwordReset',
                component: AuthenticateResetPassword
            },
            {
                path: 'onboard/:userId/:secret',
                name: 'onboard',
                component: AuthenticateOnboard
            },
        ]
    },
    {
        path: '/style-components',
        name: 'StyleComponents',
        component: StyleComponents
    },
    {
        path: '/suspended',
        name: 'suspended',
        component: ProfileSuspended
    },
    {
        path: '/addme/:compId/:frameSecret',
        name: 'addme',
        component: EmployeeOnboard
    },
    {
        path: '/',
        beforeEnter: (to, from, next) => {
            store.dispatch('currentUser').then(() => {
                let hasTrial = store.state.user ? store.state.user.attributes.trialUntil : false;

                let remainingTrial = 0;
                if(hasTrial) {
                    remainingTrial = -1 * moment().diff(store.state.user.attributes.trialUntil, 'days');
                }

                if(store.state.user.attributes.suspended ||
                    (hasTrial && remainingTrial < 0)
                ){
                    next('/suspended');
                }else {
                    if (store.state.isAuthenticated) {
                        let hasCompany = !!store.state.user.attributes.company;
                        if(hasCompany || to.name == 'client-add'){
                            next();
                        }else {
                            next({name: 'client-add'})
                        }
                    } else {
                        next('/auth')
                    }
                }
            }).catch(() => {
                if (store.state.isAuthenticated) {
                    next();
                } else {
                    next('/auth')
                }
            });
        },
        component: DashboardLayout,
        children: [
            {
                path: '',
                name: 'home',
                component: DashboardEmployeeCards,
                // beforeEnter: (to, from, next) => {
                //     store.dispatch('currentUser').then(() => {
                //         if (store.state.user.attributes.isAdmin) {
                //             next({name: 'adminCards'});
                //         } else {
                //             next();
                //         }
                //     })
                // }
            },
            {
                path: 'card-reports',
                name: 'adminCards',
                component: AdminEmployeeCards,
            },
            {
                path: 'billing-estimates',
                name: 'billingEstimate',
                component: AdminEstimates,
            },
            {
                path: 'card-design',
                name: 'cardDesign',
                component: DashboardCardDesign,
                beforeEnter: (to, from, next) => {
                    store.dispatch('getCompany').then(() => {
                        store.dispatch('getCardConfig').then((resp) => {
                            store.commit('setActiveTemplate', resp.attributes.template);
                            if (store.state.cardConfig) {
                                next();
                            } else {
                                next({name: 'adminCards'});
                            }
                        })
                    })
                }
            },

            {
                path: 'client/add',
                name: 'client-add',
                component: addClient
            },
            {
                path: 'client/information',
                name: 'client-information',
                component: ClientInformation
            },
            {
                path: 'client/addresses',
                name: 'office-addresses',
                component: DashboardCompanyCoordinates
            },
            {
                path: 'billing',
                name: 'billing',
                component: DashboardBilling
            },
            {
                path: 'profile',
                name: 'profile',
                component: DashboardProfile
            },
            {
                path: '/admin/users',
                name: 'users',
                component: UserList
            },
            {
                path: '/admin/clients',
                name: 'clients',
                component: DashboardClients
            },
        ]
    },

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
