<template>
    <div>
        <h3 class="text-center">
            {{$ml.get('DELETE_USER')}}
        </h3>
        <div class="font-bebas-neue">
            {{$ml.get('SELECTED_USERS')}}
        </div>
        <ul class="bulk-list delete">
          <li
              v-for="employee in selectedEmployees"
              :key="employee.id">
            <span><img v-if="employee.attributes.avatar"
                       :src="employee.attributes.avatar._url" width="45" height="45"><span>{{employee.attributes.firstName}}&nbsp;{{employee.attributes.lastName}}<br>{{employee.attributes.username}}</span></span>
          </li>
        </ul>
        <div class="pt-4">
            <checkbox-field color="secondary"
                            name="confirm"
                            id="confirm"
                            :value="confirm"
                            v-model="confirm">
                {{$ml.get('delete_user_confirm_checkbox')}}
            </checkbox-field>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="error"
                              icon="trash-alt"
                              :loading="loading"
                              :disabled="loading || !confirm"
                              block
                              @click="deleteCards">{{$ml.get('DELETE')}}
                </button-field>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardUserDelete",
        computed: {
            selectedEmployees() {
                return this.$store.getters.selectedModalData('visibleUsers');
            }
        },
        data() {
            return {
                confirm: false,
                loading: false,
            }
        },
        methods: {
            deleteCards() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('deleteUser').then(() => {
                        this.loading = false;

                        this.$store.dispatch('getAvailableUsers').then(() => {
                            this.loading = false;
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_success_user_delete'),
                            });
                            this.$store.commit('toggleDashboardModal');
                        });

                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    });
                }
            }
        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding-top: 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--bg-grey);
    }
    ul.bulk-list li > span{
      justify-content: flex-start;
      padding-left: 20px;
      padding-right: 20px;
    }
    ul.bulk-list li{
      min-width: 100%;
    }
</style>
