import Parse from 'parse';

export const CompanyService = {
    getCompany(context) {
        let compPointer = context.state.user.get('company');
        let theId = null;

        if(compPointer){
            theId = compPointer.id ? compPointer.id : compPointer.objectId;
        }

        if(theId){
            const compObject = Parse.Object.extend("Company");
            const compQuery = new Parse.Query(compObject);
            compQuery.include('impersonating');

            return new Promise((resolve, reject) => {
                compQuery.get(theId).then(response => {

                    context.commit('setUserCompany', response);
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            });
        }
    },
    saveCompany(context, data) {
        return new Promise((resolve, reject) => {
            for (const key in data) {
                context.state.userCompany.set(key, data[key]);
            }
            context.state.userCompany.save().then(response => {
                context.commit('setUserCompany', response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
};
