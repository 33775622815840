<template>
    <div>
        <h3 class="text-center">
            {{$ml.get('SUSPEND_CLIENT')}}
        </h3>
        <div class="font-bebas-neue">
            {{$ml.get('CLIENT_COMPANY')}}
        </div>
        <ul>
            <li class=""
                v-for="clientCompany in agencyClients"
                :key="clientCompany.id">

                <img v-if="clientCompany.attributes.logo"
                     class="comp-logo"
                     :src="clientCompany.attributes.logo._url" />
                <span>{{clientCompany.attributes.name}}</span>
            </li>
        </ul>

        <div v-if="isSuspended" >
            <div class="pt-4">
                <checkbox-field color="secondary"
                                name="confirm"
                                id="confirm"
                                :value="confirm"
                                v-model="confirm">
                    {{$ml.get('restore_client_confirm_checkbox')}}
                </checkbox-field>

            </div>
            <div class="row pt-4 justify-content-center">
                <div class="col-6">
                    <button-field color="dark"
                                  icon="sun"
                                  :loading="loading"
                                  :disabled="loading || !confirm"
                                  block
                                  @click="restoreClients">{{$ml.get('RESTORE_CLIENT')}}
                    </button-field>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="pt-4">
                <checkbox-field color="secondary"
                                name="confirm"
                                id="confirm"
                                :value="confirm"
                                v-model="confirm">
                    {{$ml.get('suspend_client_confirm_checkbox')}}
                </checkbox-field>
                <p>{{$ml.get('beware_suspend')}}</p>
            </div>

            <div class="row pt-4 justify-content-center">
                <div class="col-6">
                    <button-field color="dark"
                                  icon="moon"
                                  :loading="loading"
                                  :disabled="loading || !confirm"
                                  block
                                  @click="suspendClients">{{$ml.get('SUSPEND_CLIENT')}}
                    </button-field>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
    export default {
        name: "DashboardClientSuspend",
        computed: {
            agencyClients() {
                return this.$store.getters.selectedModalData('agencyClients');
            },
            isSuspended() {
                if(this.agencyClients && this.agencyClients[0].attributes.suspended){
                    return true;
                }else{
                    return false;
                }
            }
        },
        data() {
            return {
                confirm: false,
                loading: false,
            }
        },
        methods: {
            suspendClients() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('suspendClient').then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_suspend'),
                        });
                        this.$store.commit('setCardCompany', this.$store.state.userCompany);
                        self.$store.dispatch('getAgencyClients');
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    });
                }
            },
            restoreClients() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('restoreClient').then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_restored'),
                        });
                        this.$store.commit('setCardCompany', this.$store.state.userCompany);
                        self.$store.dispatch('getAgencyClients');
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    });
                }
            }
        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding-top: 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--bg-grey);
    }
    li span {
        font-size: 1.5em;
    }
    img.comp-logo {
        max-height: 50px;
        margin-right: 10px;
        margin-bottom: -14px;
    }
</style>
