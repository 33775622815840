<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="eco-content-scroll">
                <div class="step-container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Company information</h2>
                            <br/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <input-field :id="`name`"
                                         :name="'name'"
                                         :value="form.name"
                                         :required="true"
                                         @validation-result="validationCollector"
                                         v-model="form.name">{{ $ml.get('COMPANY_NAME') }}
                            </input-field>
                        </div>
                        <div class="col-6">
                            <input-field :id="`vatNumber`"
                                         :name="'vatNumber'"
                                         :value="form.vatNumber"
                                         :required="false"
                                         v-model="form.vatNumber">{{ $ml.get('VAT_NUMBER') }}
                            </input-field>
                        </div>
                        <div class="col-12">
                            <input-field :id="`billingAddress`"
                                         :name="'billingAddress'"
                                         :value="form.billingAddress"
                                         :required="true"
                                         @validation-result="validationCollector"
                                         v-model="form.billingAddress">{{ $ml.get('BILLING_ADDRESS') }}
                            </input-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <input-field :id="`responsible`"
                                         :name="'responsible'"
                                         :value="form.responsible"
                                         :required="false"
                                         @validation-result="validationCollector"
                                         v-model="form.responsible">{{ $ml.get('RESPONSIBLE') }}
                            </input-field>
                        </div>
                        <div class="col-6">
                            <input-field :id="`telephone`"
                                         :name="`telephone`"
                                         :value="form.telephone"
                                         :required="false"
                                         @validation-result="validationCollector"
                                         v-model="form.telephone">{{ $ml.get('TELEPHONE') }}
                            </input-field>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-6">
                            <input-field :id="`billingEmail`"
                                         :name="'billingEmail'"
                                         :value="form.billingEmail"
                                         :required="true"
                                         :ext-validator="emailValidator"
                                         @validation-result="validationCollector"
                                         v-model="form.billingEmail">{{ $ml.get('EMAIL') }}
                            </input-field>
                        </div>
                        <div class="col-6">
                            <input-field :id="`website`"
                                         :name="'website'"
                                         :value="form.website"
                                         :required="false"
                                         @validation-result="validationCollector"
                                         v-model="form.website">{{ $ml.get('WEBSITE') }}
                            </input-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-2 col-offset-10">
                            <button-field
                                color="primary"
                                @click="saveData()"
                                type="submit"
                                :disabled="isFormDisabled"
                                icon="save"
                                block>
                                {{$ml.get('SAVE_CHANGES')}}
                            </button-field>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClientInformation",
    data() {
        return {
            form: {
                name: '',
                vatNumber: '',
                billingAddress: '',
                responsible: '',
                billingEmail: '',
                telephone: '',
                website: '',
                billingName: '',
            },
            errors: [],
            editedCompany: {id:null},
        }
    },
    computed: {
        selected(){
            return this.$store.state.cardCompany;
        },
        isFormDisabled(){
            return this.errors.length > 0;
        },
    },
    mounted() {
      this.fillForm(this.selected);
      this.editedCompany = this.selected;
    },
    methods: {
        fillForm(company){
            //console.log("Card config is: ", this.cardConfig)
            this.form = {
                name: company ? company.attributes.name : '',
                vatNumber: company ? company.attributes.vatNumber : '',
                billingAddress: company ? company.attributes.billingAddress : '',
                responsible: company ? company.attributes.responsible : '',
                billingEmail: company ? company.attributes.billingEmail : '',
                telephone: company ? company.attributes.telephone : '',
                website: company ? company.attributes.website : '',
                //billingName: company ? company.attributes.billingName : '',
            };
            this.errors = [];

            //console.log("Fill form: ", company);
        },
        availableTemplates() {
            let templates = [];
            if (this.$store.state.cardTemplates) {
                this.$store.state.cardTemplates.forEach((template) => {
                    let element = {
                        value: template.id,
                        text: template.attributes.name,
                        nice_name: template.attributes.nice_name,
                    };
                    templates.push(element);
                })
            }
            return templates;
        },
        emailValidator(value) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        },
        vatValidator() {
            return function (value) {
                let re = /(AT?U[0-9]{8}$|BE?0[0-9]{9}$|BG?[0-9]{9,10}$|HR?[0-9]{11}$|CY?[0-9]{8}[A-Z]$|CZ?[0-9]{8,10}$|DE?[0-9]{9}$|DK?[0-9]{8}$|EE?[0-9]{9}$|EL?[0-9]{9}$|ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$|FI?[0-9]{8}$|FR?[0-9A-Z]{2}[0-9]{9}$|GB?([0-9]{9}([0-9]{3}?|[A-Z]{2}[0-9]{3}))$|HU?[0-9]{8}$|IE?[0-9]{7}[A-Z]{1,2}$|IE?[0-9][A-Z][0-9]{5}[A-Z]$|IT?[0-9]{11}$|LT?([0-9]{9}|[0-9]{12})$|LU?[0-9]{8}$|LV?[0-9]{11}$|MT?[0-9]{8}$|NL?[0-9]{9}B[0-9]{2}$|PL?[0-9]{10}$|PT?[0-9]{9}$|RO?[0-9]{2,10}$|SE?[0-9]{12}$|SI?[0-9]{8}$|SK?[0-9]{10})$/;
                let result = re.test(String(value));
                return result;
            };
        },
        zipCodeValidator() {
            return function (value) {
                let re = /[\w]*[\w-]*[\w ]*\d{4,}/;
                return re.test(value);
            }
        },
        validationCollector(inputValidation) {
            Array.prototype.remove = function () {
                var what, a = arguments, L = a.length, ax;
                while (L && this.length) {
                    what = a[--L];
                    while ((ax = this.indexOf(what)) !== -1) {
                        this.splice(ax, 1);
                    }
                }
                return this;
            };

            if (inputValidation.valid) {
                this.errors.remove(inputValidation.name);
            } else {
                if (this.errors.indexOf(inputValidation.name) === -1) {
                    this.errors.push(inputValidation.name);
                }
            }
        },
        saveData(){
            let self = this;
            this.editedCompany.set('name', this.form.name);
            this.editedCompany.set('vatNumber', this.form.name);
            this.editedCompany.set('billingAddress', this.form.billingAddress );
            this.editedCompany.set('responsible', this.form.responsible);
            this.editedCompany.set('billingEmail', this.form.billingEmail);
            this.editedCompany.set('telephone', this.form.telephone);
            this.editedCompany.set('website', this.form.website);
            //this.editedCompany.set('billingName', this.form.billingName);
            this.editedCompany.save().then(function(){
                self.$notify({
                    group: 'notification',
                    type: 'success',
                    text: self.$ml.get('notification_success_saved'),
                });
            }).catch(function(){
                self.$notify({
                    group: 'notification',
                    type: 'error',
                    title: self.$ml.get('notification_error_title'),
                    text: self.$ml.get('notification_error_general'),
                });
                //console.log(err)
            });
        }
    },
    watch: {
        selected(newVal){
            let self = this;
            if(newVal.id !== this.editedCompany.id){
                this.editedCompany = newVal;
                this.$store.dispatch('getCardConfig').then(function(){
                    self.fillForm(newVal);
                });

            }
        }
    }
}
</script>

<style scoped>
.step-container {
    max-width: 800px;
    margin: 0 auto;
}

.qf-wizzard-tabs li {
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: var(--color-dark);
    padding: .2em .8em;
    border-bottom: 2px solid transparent;
    border-bottom-color: transparent;
    -webkit-transition: color .2s, border-color .2s;
    transition: color .2s, border-color .2s;
    cursor: pointer;
}

.qf-wizzard-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: block;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
</style>
