<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="addressForm" icon="plus" color="primary">{{$ml.get('ADD_ADDRESS')}}</button-field>
                </div>
            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                        <tr>
                            <th>{{$ml.get('ADDRESS_LABEL')}}</th>
                            <th>{{$ml.get('OFFICE_PHONE')}}</th>
                            <th>{{$ml.get('WEBSITE')}}</th>
                            <th>{{$ml.get('ADDRESS')}}</th>
                            <th class="actions">{{$ml.get('ACTIONS')}}</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(address) in companyAddresses" :key="address.id">
                            <td>
                                <div>{{address.attributes.officeLabel}}</div>
                            </td>
                            <td>
                                <div>{{address.attributes.officePhone}}</div>
                            </td>
                            <td>
                                <div>{{address.attributes.website}}</div>
                            </td>
                            <td>
                                <div>{{address.attributes.street}}, {{address.attributes.zip}} {{address.attributes.city}}, <span v-if="address.attributes.state">{{address.attributes.state}},</span> {{address.attributes.country}}</div>
                            </td>
                            <td>
                                <div class="table-action-btn-gorup">
                                    <button-field @click="addressForm(address.id)"
                                                  color="primary"
                                                  circle
                                                  outline
                                                  v-tooltip="`Edit this address`"
                                                  icon="edit"
                                                  class="table-action-btn"></button-field>
                                    <button-field @click="deleteAddress(address.id)"
                                                  color="error"
                                                  circle
                                                  outline
                                                  v-tooltip="`Delete address`"
                                                  icon="trash-alt"
                                                  class="table-action-btn bg-red"></button-field>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "DashboardCompanyCoordinates",
        computed: {
            companyAddresses() {
                return this.$store.state.officeAddresses;
            }
        },
        data() {
            return {
                tableRows: this.generateRows(4)
            }
        },
        mounted() {
            this.getCompanyAddresses();
            this.$store.commit('closeRightModal');
        },
        methods: {
            getCompanyAddresses() {
                let self = this;
                this.$store.dispatch('getCompany').then(() => {
                    this.$store.dispatch('getOfficeAddresses').then(() => {
                    }).catch(function(e){
                        return Promise.reject(e);
                    });
                }).catch(function(e2){
                    self.$checkTokenExp(e2);
                });
            },
            toggleModal() {
                this.$store.commit('toggleDashboardModal')
            },
            addressForm(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardCompanyCoordinatesForm', data: {id: id}})
            },
            deleteAddress(id) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardCompanyCoordinatesDelete', data: {id: id}})
            },
            generateRows(rows) {
                let allItems = Array()
                let one_row = {
                    address_label: 'Sofia Office',
                }
                let i = 0
                while (i < rows) {
                    allItems[i] = one_row
                    i++
                }
                return allItems
            }
        }
    }
</script>

<style scoped>

</style>
